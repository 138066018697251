import { useSelector, useDispatch } from 'react-redux'
import { getUser } from '@/redux/actions/userActions'
import { setLoading } from '@/redux/actions/authActions'

export const useUserModel = () => {
  const dispatch = useDispatch()
  const userId = useSelector((state) => state.auth.secureStore.userId)
  const userInfo = useSelector((state) => state.user.userInfo)
  const isLoading = useSelector((state) => state.auth.isLoading)

  const fetchUserData = async () => {
    if (userId) {
      getUser(userId, dispatch)
    }
  }

  const setIsLoading = (state) => {
    setLoading(state, dispatch)
  }

  return {
    userId,
    userInfo,
    isLoading,
    fetchUserData,
    setIsLoading,
  }
}
