import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  cardEvent: {
    borderRadius: 5,
    minHeight: 100,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  backgroundImage: {
    borderRadius: 5,
    minHeight: 100,
    overflow: 'hidden',
  },
  content: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: 15,
  },
  imageEventImg: {
    height: 80,
    width: 80,
    borderRadius: 3,
    resizeMode: 'cover',
    marginLeft: 10,
  },
  eventName: {
    color: 'white',
    fontWeight: '200',
    fontSize: 16,
  },
  buttonDiv: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  buttonCompartir: {
    backgroundColor: 'black',
    borderRadius: 7,
    padding: 8,
    minWidth: 95,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonAccion: {
    backgroundColor: '#8811c8',
    borderRadius: 7,
    padding: 8,
    minWidth: 95,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: 'white',
    fontSize: 12,
  },
  estadoPendiente: {
    backgroundColor: '#c68f0f',
    height: '100%',
    width: 45,
    borderRadius: 5,
    alignItems: 'center',
    paddingTop: 22,
  },
  estadoConfirmado: {
    backgroundColor: '#338d36',
    height: '100%',
    width: 45,
    borderRadius: 5,
    alignItems: 'center',
    paddingTop: 27,
  },
  estadoTuEvento: {
    backgroundColor: '#8811c8',
    height: '100%',
    width: 45,
    borderRadius: 5,
    alignItems: 'center',
    paddingTop: 22,
  },
  estadoText: {
    transform: [{ rotate: '-90deg' }],
    color: 'white',
    width: 100,
    fontSize: 12.5,
  },
})

export default styles
