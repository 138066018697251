import React from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ActivityIndicator,
} from 'react-native'
import bond from '@/assets/People/vinculo.png'
import styles from './ActionButtonStyles'
import { useActionButtonController } from './controllers/useActionButtonController'
import { GetContactMethodModal } from '@/components'

const ActionButton = ({ guest }) => {
  const {
    sendBondRequest,
    acceptBondRequest,
    isOpenContactModal,
    setIsOpenContactModal,
    requestId,
    state,
    setState,
  } = useActionButtonController({ guest })

  const getButtonDetails = () => {
    switch (state) {
      case 'noBondRequest':
        return {
          text: 'Vincularme',
          backgroundColor: '#8811c8',
          circleColor: '#661099',
          icon: <Image source={bond} style={styles.icon} />,
          btnDisabled: false,
          action: () => {
            sendBondRequest(guest.userId)
            setState('pendingRequestSent')
          },
        }
      case 'pendingRequestReceived':
        return {
          text: 'Aceptar',
          backgroundColor: '#8811c8',
          circleColor: '#661099',
          icon: <Image source={bond} style={styles.icon} />,
          btnDisabled: false,
          action: () => {
            acceptBondRequest(requestId, 'accepted')
            setState('bond')
          },
        }
      case 'pendingRequestSent':
        return {
          text: 'Pendiente',
          backgroundColor: '#FFB407',
          circleColor: '#FFA000',
          icon: <Image source={bond} style={styles.icon} />,
          btnDisabled: true,
          action: () => {},
        }
      case 'bond':
        return {
          text: 'Vinculados',
          backgroundColor: '#4CAF50',
          circleColor: '#388E3C',
          icon: <Image source={bond} style={styles.icon} />,
          btnDisabled: false,
          action: () => setIsOpenContactModal(true),
        }
      case 'error':
        return {
          text: 'Error',
          backgroundColor: '#b00000',
          circleColor: '#a00000',
          icon: <Text>X</Text>,
          btnDisabled: true,
          action: () => {},
        }
      case 'loading':
      default:
        return {
          text: 'Cargando',
          backgroundColor: '#e0e0e0',
          circleColor: '#bdbdbd',
          icon: <ActivityIndicator size="large" color="#ffffff" />,
          btnDisabled: true,
          action: () => {},
        }
    }
  }

  const { text, backgroundColor, circleColor, icon, btnDisabled, action } =
    getButtonDetails()

  return (
    <View style={styles.buttonContainer}>
      <TouchableOpacity
        style={[styles.thermometerButton, { backgroundColor }]}
        onPress={action}
        disabled={btnDisabled}
      >
        <Text style={styles.buttonText}>{text}</Text>
        <View style={[styles.circle, { backgroundColor: circleColor }]}>
          {icon}
        </View>
      </TouchableOpacity>
      <GetContactMethodModal
        guestInfo={guest}
        isOpenContactModal={isOpenContactModal}
        setIsOpenContactModal={setIsOpenContactModal}
      />
    </View>
  )
}

export default ActionButton
