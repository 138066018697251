import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  contactBondBtn: {
    backgroundColor: '#8811c8',
    borderRadius: 3,
    height: 25,
    width: 80,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactBondBtnLabel: {
    color: 'white',
  },
})
