import React, { useEffect, useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  Modal,
  ImageBackground,
  Image,
} from 'react-native'
import backgroundImage from '@/assets/Navbar/fondoNavbar.png'
import plus from '@/assets/Profile/subir2.png'
import emptyProfilePic from '@/assets/Profile/emptyProfilePic.png'
import styles from './InfoModalStyles'

export default function InfoModal({
  type,
  isOpenModal,
  setIsOpenModal,
  functionality,
}) {
  const [info, setInfo] = useState(undefined)
  const isDeclined = () => {
    setIsOpenModal(false)
  }

  const isConfirmed = () => {
    functionality('modal')
  }

  useEffect(() => {
    switch (type) {
      case 'emptyProfilePic':
        setInfo({
          text: 'Agregá una foto de perfil para que podamos identificarte en el ingreso. También vas a poder vincularte con todas las personas que irán al evento.',
          img: emptyProfilePic,
          buttons: [
            {
              text: 'Agregar',
              icon: plus,
              action: isConfirmed,
            },
            {
              text: 'Más tarde',
              action: isDeclined,
            },
          ],
        })
        break

      default:
        break
    }
  }, [])

  return (
    <Modal transparent={true} visible={isOpenModal} animationType="fade">
      <View style={styles.background}>
        <ImageBackground
          source={backgroundImage}
          style={styles.modal}
          imageStyle={styles.backgroundImage}
        >
          <Text style={styles.text}>{info && info.text}</Text>
          <Image source={info && info.img} style={styles.imgInfo} />
          <View style={styles.buttonContainer}>
            {info &&
              info.buttons.map((button, index) => (
                <TouchableOpacity
                  onPress={button.action}
                  style={styles.btn}
                  key={index}
                >
                  {button.icon && (
                    <Image source={button.icon} style={styles.btnIcon} />
                  )}
                  <Text style={styles.btnText}>{button.text}</Text>
                </TouchableOpacity>
              ))}
          </View>
        </ImageBackground>
      </View>
    </Modal>
  )
}
