import React from 'react'
import { View, ScrollView } from 'react-native'
import { Loader, ScreenLayout } from '@/components'
import { useMyEventsController } from './controllers/MyEventsController'
import styles from './MyEventsStyles'
import EventCard from './components/EventCard/EventCard'

const MyEvents = () => {
  const { userEvents, isLoading, miTicket } = useMyEventsController()

  return (
    <ScreenLayout headerTitle={'Mis eventos'}>
      {isLoading ? (
        <Loader />
      ) : (
        <ScrollView
          style={styles.container}
          showsVerticalScrollIndicator={false}
        >
          <View style={styles.cardEventContainer}>
            {userEvents &&
              userEvents.map((event, index) => (
                <EventCard
                  eventInfo={event}
                  index={index}
                  key={index}
                  miTicket={miTicket}
                />
              ))}
          </View>
        </ScrollView>
      )}
    </ScreenLayout>
  )
}

export default MyEvents
