import React from 'react'
import { TouchableOpacity, Image } from 'react-native'
import styles from './ProfilePicStyles'

const ProfilePic = ({ guest, goToDetail }) => {
  return (
    <TouchableOpacity
      onPress={() => goToDetail(guest)}
      style={styles.container}
    >
      <Image
        source={{ uri: guest.profilePictures[0] }}
        style={styles.image}
        resizeMode="cover"
      />
    </TouchableOpacity>
  )
}

export default ProfilePic
