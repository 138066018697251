import React, { useState } from 'react'
import { View, Text, TouchableOpacity, Modal, FlatList } from 'react-native'
import styles from './SelectGenderRegisterStyles'

const SelectGenderRegister = ({
  userData,
  setUserData,
  visible,
  setSelectGenderOpen,
}) => {
  const genders = ['Masculino', 'Femenino', 'Otro']
  const [selectedGender, setSelectedGender] = useState(userData.genre || '')

  const handleSelectGender = (gender) => {
    setSelectedGender(gender)
    setUserData({ ...userData, genre: gender })
    setSelectGenderOpen(false)
  }

  const renderItem = ({ item }) => (
    <TouchableOpacity
      style={[
        styles.genderItem,
        { backgroundColor: item === selectedGender ? '#9459CE' : 'black' },
      ]}
      onPress={() => handleSelectGender(item)}
    >
      <Text style={styles.genderText}>{item}</Text>
    </TouchableOpacity>
  )

  return (
    <>
      <TouchableOpacity
        onPress={() => setSelectGenderOpen(true)}
        style={styles.openButton}
      >
        <Text style={styles.openButtonText}>{selectedGender || 'Género'}</Text>
      </TouchableOpacity>
      <Modal
        visible={visible}
        transparent={true}
        onRequestClose={() => setSelectGenderOpen(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <FlatList
              data={genders}
              renderItem={renderItem}
              keyExtractor={(item) => item}
              style={styles.flatList}
            />
          </View>
        </View>
      </Modal>
    </>
  )
}

export default SelectGenderRegister
