import { StyleSheet, Platform } from 'react-native'

export default StyleSheet.create({
  container: {
    backgroundColor: 'black',
    height: '100%',
  },
  searchbar: {
    marginTop: Platform.OS === 'ios' ? 70 : 40,
    paddingHorizontal: 15,
  },
  resultText: {
    color: 'red',
  },
})
