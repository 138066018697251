import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  modalContent: {
    backgroundColor: 'black',
    borderRadius: 5,
    padding: 20,
    width: '90%',
    maxHeight: '80%',
    borderColor: '#ccc',
    borderWidth: 0.5,
    marginTop: '90%',
  },
  genderItem: {
    padding: 5,
    borderRadius: 5,
  },
  genderText: {
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    fontWeight: '300',
  },
  openButton: {
    padding: 10,
    borderRadius: 5,
    width: '100%',
    borderColor: '#ccc',
    borderWidth: 1,
  },
  openButtonText: {
    color: 'white',
    fontWeight: '300'
  },
})
