import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    flex: 1,
    height: 100,
    // justifyContent: 'center', // Centra el contenido verticalmente
    // alignItems: 'center', // Centra el contenido horizontalmente
  },
  header:{
    flexDirection:'row',
    justifyContent:'space-around',
    width:'100%',
    backgroundColor:'red'
  },
  banner: {
    width: '100%',
    height: 400, // Ajusta el tamaño del contenedor según necesites
    justifyContent: 'top', // Asegura que el contenido dentro del banner esté centrado
    alignItems: 'top',
    overflow: 'hidden', // Oculta cualquier parte del video que se salga del contenedor
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Mantiene el video centrado, mostrando las partes importantes
    resizeMode:"stretch"
    // aspectRatio: 16 / 9, // Asegura que el video mantenga una proporción adecuada
  },
  logo: {
    position: 'absolute',
    top: 10,
    left: 10,
    width: 100,
    height: 50,
  },
  loginButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
});
