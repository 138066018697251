import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
  },
  loaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 120,
    alignItems: 'center',
    transform: [{ rotate: '-25deg' }],
  },
  diamond: {
    width: 50,
    height: 50,
    borderWidth: 6,
    marginHorizontal: 20,
    borderColor: '#DD65FB',
    transform: [{ rotate: '45deg' }],
    borderRadius: 5,
    backgroundColor: 'transparent',
  },
});
