import config from "config"
import { changeDefaultPic } from "./guest.adapter"

const URL_API_VINCUFY = config.URL_API_VINCUFY

export const bondAdapter = (bondDB) => {
    let adaptedBond = bondDB

    adaptedBond = changeDefaultPic(adaptedBond)

    return adaptedBond
}

export const bondsAdapter = (bondsDB) => {
    let adaptedBonds = bondsDB.map(bondDB => bondAdapter(bondDB))

    // adaptedBonds = shuffleArray(adaptedBonds)
    // adaptedBonds = filterLoggedUserAndBonds(adaptedBonds, userId, bonds)

    return adaptedBonds
}

export const bondRequestAdapter = (bondRequestDB) => {
    let adaptedBondRequest = bondRequestDB.Requester

    return adaptedBondRequest
}

export const bondRequestsAdapter = (bondRequestsDB) => {
    let adaptedBondRequests = bondRequestsDB.map(bondRequestDB => bondRequestAdapter(bondRequestDB))

    return adaptedBondRequests
}