import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { People, ProfileDetail, BondRequests } from '@/views'
import ContactMethodScreen from '../../src/views/MyProfile/components/ContactMethodScreen/ContactMethodScreen'

const Stack = createNativeStackNavigator()

const PeopleStack = () => {
  return (
    <Stack.Navigator
      initialRouteName="People"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name="People" component={People} />
      <Stack.Screen name="ProfileDetail" component={ProfileDetail} />
      <Stack.Screen name="BondRequests" component={BondRequests} />
      <Stack.Screen
        name="ContactMethodScreen"
        component={ContactMethodScreen}
      />
    </Stack.Navigator>
  )
}

export default PeopleStack
