import React, { useState } from 'react'
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Image,
  ImageBackground,
  ActivityIndicator,
} from 'react-native'
import { editUser } from '@/redux/actions/userActions'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { useDispatch, useSelector } from 'react-redux'
import styles from './RegisterUserDataStyles'
import logoVincufy from '@/assets/Header/logoHeaderVincufy.png'
import backgroundImg from '@/assets/Auth/backgroundAuth.png'
import DatePickerCalendarRegister from '../Inputs/DatePickerCalendarRegister/DatePickerCalendarRegister'
import SelectGenderRegister from '../Inputs/SelectGenderRegister/SelectGenderRegister'
import { useNavigation } from '@react-navigation/native'
import { SET_IS_USERINFO_EMPTY } from '@/redux/actions/index'

const RegisterUserData = () => {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [selectGenderOpen, setSelectGenderOpen] = useState(false)
  const [isLoadingRegister, setIsLoadingRegister] = useState(false)
  const [input, setInput] = useState({
    name: '',
    lastName: '',
    dateOfBirth: '',
    city: '',
    genre: '',
  })
  const userId = useSelector((state) => state.auth.secureStore.userId)

  const handleChange = (e, name) => {
    const newInput = { ...input, [name]: e }
    setInput(newInput)
  }

  const handleSubmit = async () => {
    if (userId) {
      try {
        setIsLoadingRegister(true)
        editUser(userId, input, dispatch)
        setTimeout(() => {
          setIsLoadingRegister(false)
          navigation.navigate('MyTabs', { screen: 'MyProfileStack' });
        }, 2000)
        dispatch({
          type: SET_IS_USERINFO_EMPTY,
          payload: false,
        })
      } catch {
        Alert.alert('Ups algo salió mal, intentalo de nuevo!')
      }
    }
  }

  const registerBtnDisabled =
    input.name.length === 0 ||
    input.lastName.length === 0 ||
    input.dateOfBirth.length === 0 ||
    input.city.length === 0 ||
    input.genre.length === 0

  return (
    <ImageBackground
      source={backgroundImg}
      style={styles.backgroundImage}
      resizeMode="cover"
    >
      <KeyboardAwareScrollView
        contentContainerStyle={styles.gral}
        keyboardShouldPersistTaps="handled"
      >
        <Image source={logoVincufy} style={styles.logoVincufy} />
        <View style={styles.inputDiv}>
          <TextInput
            value={input.name}
            placeholder="Ingresá tu nombre"
            maxLength={15}
            placeholderTextColor="rgb(96, 96, 96)"
            style={styles.input}
            onChangeText={(text) => handleChange(text, 'name')}
            autoCorrect={false}
          />
          <TextInput
            value={input.lastName}
            placeholder="Ingresá tu apellido"
            placeholderTextColor="rgb(96, 96, 96)"
            style={styles.input}
            maxLength={15}
            onChangeText={(text) => handleChange(text, 'lastName')}
            autoCorrect={false}
          />
          <DatePickerCalendarRegister
            userData={input}
            setUserData={setInput}
            showDatePicker={showDatePicker}
            setShowDatePicker={setShowDatePicker}
          />
          <TextInput
            value={input.city}
            placeholder="Ciudad de residencia"
            placeholderTextColor="rgb(96, 96, 96)"
            style={styles.input}
            maxLength={20}
            onChangeText={(text) => handleChange(text, 'city')}
            autoCorrect={false}
          />
          <SelectGenderRegister
            userData={input}
            setUserData={setInput}
            visible={selectGenderOpen}
            setSelectGenderOpen={setSelectGenderOpen}
          />
        </View>

        <TouchableOpacity
          onPress={handleSubmit}
          style={styles.registerButton}
          disabled={registerBtnDisabled}
        >
          {isLoadingRegister ? (
            <ActivityIndicator size="small" color="#ffffff" />
          ) : (
            <Text style={styles.buttonText}>Crear Cuenta</Text>
          )}
        </TouchableOpacity>
      </KeyboardAwareScrollView>
    </ImageBackground>
  )
}

export default RegisterUserData
