import React, { useEffect } from 'react'
import { StatusBar } from 'expo-status-bar'
import Navigation from './routes/Navigation'
import { Provider } from 'react-redux'
import { store } from '@/redux/store/store'
import { NavigationContainer } from '@react-navigation/native'
import qrVincufy from 'assets/qrVincufy2.PNG'
import logoVincufy from 'assets/logoHeaderVincufy.png'

const App = () => {
  useEffect(() => {
    //   if ('serviceWorker' in navigator) {
    //     window.addEventListener('load', () => {
    //       navigator.serviceWorker
    //         .register('/firebase-messaging-sw.js')
    //         .then((registration) => {
    //           registration.active?.postMessage({
    //             firebaseConfig: {
    //               apiKey: process.env.FIREBASE_API_KEY,
    //               authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    //               projectId: process.env.FIREBASE_PROJECT_ID,
    //               storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    //               messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    //               appId: process.env.FIREBASE_APP_ID,
    //             },
    //           })
    //           console.log('Service Worker registrado con éxito:', registration)
    //         })
    //         .catch((error) => {
    //           console.error('Error al registrar el Service Worker:', error)
    //         })
    //     })
    //   }
    if (isDesktop()) {
      // Si es una computadora, muestra un mensaje o redirige
      document.body.innerHTML = `
      <div style="display: flex; font-family: Verdana, Geneva, sans-serif;justify-content: center; align-items: center; height: 100vh; width: 100vw; text-align: center; flex-direction: column;">
      <div style="background-color:black; width: 60%;padding:55px; border-radius:8px">  
              <img src="${logoVincufy}" alt="Acceso Restringido" style=" height: 8%; margin-bottom: 60px;">
      <h1 style="font-size: 1.6rem; color:white; margin-bottom: 20px;">Acceso Restringido</h1>
        <p style="font-size: 1.15rem;color:white;">Esta aplicación fue construida para dispositivos móviles.</p>
        <p style="font-size: 1.15rem;color:white; margin-bottom: 30px;">  Continuá desde tu celular escaneando este QR.</p>
        <img src="${qrVincufy}" alt="Acceso Restringido" style="max-width: 300px; height: 20%; border-radius: 5px; margin-bottom: 20px;">
        </div>  
        </div>`
    }
  }, [])

  const isDesktop = () => {
    const userAgent = navigator.userAgent
    return !/Mobi|Android/i.test(userAgent)
  }

  return (
    <>
      <Provider store={store}>
        <StatusBar style="light" />
        <NavigationContainer>
          <Navigation />
        </NavigationContainer>
      </Provider>
    </>
  )
}

export default App
