import React from 'react'
import styles from './SectionStyles'
import backIcon from '@/assets/Section/goBack.png'
import { View, Text, Image, TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'

export default function Section({ place, goBackUrl }) {
  const navigation = useNavigation()

  return (
    <>
      <View style={styles.container}>
        <View style={styles.box}>
          {!!goBackUrl && (
            <TouchableOpacity onPress={() => navigation.navigate(goBackUrl)}>
              <Image source={backIcon} style={styles.icon} />
            </TouchableOpacity>
          )}
          <View style={styles.textAlign}>
            <Text style={styles.text}>{place}</Text>
          </View>
        </View>
      </View>
    </>
  )
}
