import { useState } from 'react'
import { View, TextInput, TouchableOpacity, Image } from 'react-native'
import styles from './PasswordStyles'
import show from '@/assets/Password/show.png'
import hide from '@/assets/Password/hide.png'

export default function Password({ value, onChange, hasError, placeholder }) {
  const [showPass, setShowPass] = useState(false)

  return (
    <View style={styles.passwordContainer}>
      <TextInput
        value={value}
        placeholder={placeholder}
        placeholderTextColor="rgb(96, 96, 96)"
        style={styles.input}
        secureTextEntry={!showPass}
        onChangeText={(e) => onChange(e)}
        autoCapitalize="none"
        autoCorrect={false}
      />
      <TouchableOpacity
        onPress={() => setShowPass((v) => !v)}
        style={styles.showHideButton}
      >
        <Image
          source={showPass ? show : hide}
          tintColor={hasError ? 'red' : 'black'}
          style={styles.icon}
        />
      </TouchableOpacity>
    </View>
  )
}
