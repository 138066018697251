import React from 'react'
import { View, Modal, TouchableOpacity, Text, Image } from 'react-native'
import {
  styles,
  backgroundImage,
  mail,
  password,
  phone,
} from './ModalProfileSettingsStyles'
import { useNavigation } from '@react-navigation/native'
import { logoutAction } from '@/redux/actions/authActions'
import { useDispatch, useSelector } from 'react-redux'

const ProfileSettings = ({ openSettingsModal, setOpenSettingsModal }) => {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const userEmail = useSelector((state) => state.auth.secureStore.userEmail)

  const handleModalClose = (e) => {
    if (e.target === e.currentTarget) {
      setOpenSettingsModal(false)
    }
  }

  const change = (payload) => {
    if (payload !== 'password') {
      navigation.navigate('ChangeInfoSettings', {
        item: payload,
      })
      setOpenSettingsModal(false)
    } else {
      // navigation.navigate(`/forgotPw2`)
    }
  }

  const logout = async () => {
    try {
      await logoutAction(userEmail, dispatch, navigation)
    } catch (error) {}
  }

  return (
    <>
      <Modal
        visible={openSettingsModal}
        animationType="fade"
        transparent={true}
        onRequestClose={handleModalClose}
      >
        <TouchableOpacity
          style={styles.modalOverlay}
          activeOpacity={1}
          onPress={handleModalClose}
        >
          <View style={styles.modalContent}>
            <Image source={backgroundImage} style={styles.bckgImg} />
            <View style={styles.buttonsContainer}>
              <TouchableOpacity
                style={styles.button}
                activeOpacity={1}
                onPress={() => change('mail')}
              >
                <Image source={mail} style={styles.icon} />
                <Text style={styles.text}>Cambiar mail</Text>
              </TouchableOpacity>
              {/* <TouchableOpacity
                style={styles.button}
                activeOpacity={1}
                onPress={() => change('password')}
              >
                <Image source={password} style={styles.icon} />
                <Text style={styles.text}>Cambiar contraseña</Text>
              </TouchableOpacity> */}
              <TouchableOpacity
                style={styles.button}
                activeOpacity={1}
                onPress={() => change('teléfono')}
              >
                <Image source={phone} style={styles.icon} />
                <Text style={styles.text}>Cambiar teléfono</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.logOut}
                activeOpacity={1}
                onPress={logout}
              >
                <Text style={styles.text}>Cerrar sesión</Text>
              </TouchableOpacity>
            </View>
          </View>
        </TouchableOpacity>
      </Modal>
    </>
  )
}

export default ProfileSettings
