import { StyleSheet, Platform } from 'react-native'

export default StyleSheet.create({
  container: {
    flex: 1, // Asegúrate de que el contenedor ocupe todo el espacio disponible
    backgroundColor: 'black',
    alignItems: 'center',
  },
  content: {
    flex: 1, // Permite que el contenido ocupe el espacio disponible
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 20,
  },
  title: {
    color: 'white',
    textAlign: 'center',
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 40,
  },
})
