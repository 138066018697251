import React from 'react'
import { View, Image, Text, TouchableOpacity } from 'react-native'
import styles from './ConfigButtonsStyles'
import settings from '@/assets/Profile/ajustes.png'
import editInfoIcon from '@/assets/Profile/editInfo1.png'
import backgroundImage from '@/assets/Navbar/fondoNavbar.png'

const ConfigButtons = ({
  editInfo,
  setEditInfo,
  setOpenSettingsModal,
  saveModifiedInputs,
  cancelModifiedInputs,
}) => {
  return (
    <>
      <View style={styles.container}>
        {editInfo ? (
          <TouchableOpacity onPress={() => cancelModifiedInputs()}>
            <Image source={backgroundImage} style={styles.bckgImg} />
            <View style={styles.button2}>
              <Text style={styles.text}>Cancelar</Text>
            </View>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity onPress={() => setEditInfo(true)}>
            <View style={styles.button}>
              <Image source={editInfoIcon} style={styles.edit} />
              <Text style={styles.text}>Editar</Text>
            </View>
          </TouchableOpacity>
        )}
        {editInfo ? (
          <TouchableOpacity onPress={() => saveModifiedInputs()}>
            <Image source={backgroundImage} style={styles.bckgImg} />
            <View style={styles.button2}>
              <Text style={styles.text}>Guardar</Text>
            </View>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity onPress={() => setOpenSettingsModal(true)}>
            <View style={styles.button}>
              <Image source={settings} style={styles.settings} />
              <Text style={styles.text}>Ajustes</Text>
            </View>
          </TouchableOpacity>
        )}
      </View>
    </>
  )
}

export default ConfigButtons
