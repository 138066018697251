import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Vibration } from 'react-native'
import {
  getBondRequestResponseAction,
  sendBondRequestAction,
  respondBondRequestAction,
} from '@/redux/actions/bondActions'
import { useEffect } from 'react'

export const useActionButtonController = ({ guest }) => {
  const dispatch = useDispatch()
  const userId = useSelector((state) => state.auth.secureStore.userId)
  const eventId = useSelector((state) => state.event.selectedEventId)
  const actionButtonState = useSelector(
    (state) => state.bond.bondRequestResponse?.status ?? 'loading',
  )
  const requestId = useSelector(
    (state) => state.bond.bondRequestResponse?.requestId,
  )
  const [isOpenContactModal, setIsOpenContactModal] = useState(false)
  const [state, setState] = useState(actionButtonState)

  useEffect(() => {
    if (guest.userId && eventId) {
      getBondRequestResponseAction(userId, guest.userId, eventId, dispatch)
    }
  }, [guest.userId])

  useEffect(() => {
    setState(actionButtonState)
  }, [actionButtonState])

  const sendBondRequest = (receiverId) => {
    if (eventId) {
      sendBondRequestAction(userId, receiverId, eventId)
      Vibration.vibrate()
    }
  }

  const acceptBondRequest = (requestId, status) => {
    if ((requestId, status)) {
      if (requestId) {
        respondBondRequestAction(requestId, status)
      }
      Vibration.vibrate()
    }
  }

  return {
    sendBondRequest,
    actionButtonState,
    acceptBondRequest,
    isOpenContactModal,
    setIsOpenContactModal,
    requestId,
    state,
    setState,
  }
}
