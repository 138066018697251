import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  cardContainer: {
    flex: 1,
    marginTop: 15,
  },
  containerLoading: {
    flex: 1,
    backgroundColor: 'black',
    justifyContent: 'center',
  },
})
