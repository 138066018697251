import { StyleSheet, Dimensions } from 'react-native';

const { width, height } = Dimensions.get('window'); // Obtener dimensiones de la pantalla

export default StyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    width: width * 0.9, // Ocupa el 90% del ancho de la pantalla
    minHeight: "22%", // Ocupa al menos el 40% del alto de la pantalla
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 45
  },
  backgroundImage: {
    borderRadius: 10,
    resizeMode: 'stretch', // Ajusta la imagen para que cubra todo el fondo
  },
  text: {
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
    lineHeight: 25
  },
  btn: {
    backgroundColor: '#9459CE',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    alignItems:'center'
  },
  btnText: {
    color: 'white',
    fontSize: 14,
  },
});
