import { StyleSheet, Dimensions } from 'react-native'
const { width, height } = Dimensions.get('window')

export default StyleSheet.create({
  carouselContainer: {
    height: height / 2.5,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative', // Agregar esto para asegurar que los elementos internos se posicionen correctamente
    width: width,
  },
  imageContainer: {
    width: width,
    height: '100%',
    position: 'relative',
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  buttonsContainer: {
    position: 'absolute',
    bottom: 10, // Mantén esto para que se posicione en la parte inferior
    right: 10, // Asegúrate de que sea correcto
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent', // Cambiar a 'transparent'
    zIndex: 1, // Asegúrate de que los botones estén por encima de otros elementos
  },
  editButton: {
    width: 40,
    height: 40,
    resizeMode: 'contain',
    marginRight: 10, // Espacio entre los botones
  },
  indicatorContainer: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: 10,
  },
  indicator: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: '#888',
    marginHorizontal: 5,
  },
  activeIndicator: {
    backgroundColor: '#333',
  },
})
