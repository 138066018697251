import React, { useState } from 'react'
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Modal,
  FlatList,
} from 'react-native'
import styles from './ModalItemsStyles'

const ModalItems = ({
  showModalSelect,
  setShowModalSelect,
  status,
  setStatus,
  selectedContacts,
  setSelectedContacts,
}) => {
  const handleSelectStatus = (selectedItem) => {
    if (!selectedContacts[selectedItem.value]) {
      setSelectedContacts((prevState) => ({
        ...prevState,
        [selectedItem.value]: '',
      }))
      setStatus((prevStatus) =>
        prevStatus.filter((item) => item.value !== selectedItem.value),
      )
    }
    setShowModalSelect(false)
  }

  const renderItem = ({ item, index }) => (
    <TouchableOpacity
      style={[
        styles.statusItem,
        { borderBottomWidth: index === status.length - 1 ? 0 : 0.5 },
      ]}
      onPress={() => handleSelectStatus(item)}
    >
      <View style={styles.itemContainer}>
        <View style={styles.itemContent}>
          <Image source={item.icon} style={styles.icon} />
          <Text style={styles.statusText}>{item.label}</Text>
        </View>
      </View>
    </TouchableOpacity>
  )

  return (
    <>
      <Modal
        visible={showModalSelect}
        transparent={true}
        onRequestClose={() => setShowModalSelect(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <FlatList
              data={status}
              renderItem={renderItem}
              keyExtractor={(item) => item.value}
              style={styles.flatList}
              showsVerticalScrollIndicator={true}
              contentContainerStyle={{ paddingBottom: 0 }}
            />
          </View>
        </View>
      </Modal>
    </>
  )
}

export default ModalItems
