import React, { useState, useEffect } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  TextInput,
  ImageBackground,
  ScrollView,
} from 'react-native'
import styles from './SelectContactMethodTypeStyles'
import instagramIcon from '../../../assets/People/instagram.png'
import whatsappIcon from '../../../assets/People/whatsapp.png'
import linkedinIcon from '../../../assets/People/linkedin.png'
import gmailIcon from '../../../assets/People/gmail.png'
import signoMas from '../../../assets/People/mas.png'
import backgroundButtonImage from '../../../assets/Navbar/fondoNavbar.png'
import trashIcon from '../../../assets/People/trashcan.png'
import ModalItems from './ModalItems/ModalItems'
import { useMyProfileController } from '@/views/MyProfile/controllers/MyProfileController'
import { useNavigation } from '@react-navigation/native'

const SelectContactMethodType = ({
  showModalSelect,
  setShowModalSelect,
  origin,
}) => {
  const navigation = useNavigation()
  const { saveNewContactsMethods, userInfo } = useMyProfileController()

  const initialStatus = [
    { label: 'Instagram', value: 'instagram', icon: instagramIcon },
    { label: 'WhatsApp', value: 'whatsapp', icon: whatsappIcon },
    { label: 'Linkedin', value: 'linkedin', icon: linkedinIcon },
    { label: 'Mail', value: 'mail', icon: gmailIcon },
  ]

  const prefixes = {
    instagram: '@',
    whatsapp: '+549',
    linkedin: '/ini/',
    mail: '',
  }

  const [selectedContacts, setSelectedContacts] = useState({})
  const [status, setStatus] = useState(initialStatus)
  const [errors, setErrors] = useState({})
  const [originalContacts, setOriginalContacts] = useState({})
  const userContactMethods = userInfo?.contactMethods

  useEffect(() => {
    if (userContactMethods) {
      const updatedContacts = {}
      Object.keys(userContactMethods).forEach((key) => {
        updatedContacts[key] = userContactMethods[key]
      })
      setSelectedContacts(updatedContacts)
      setOriginalContacts(updatedContacts)

      const updatedStatus = initialStatus.filter(
        (item) => !Object.keys(userContactMethods).includes(item.value),
      )
      setStatus(updatedStatus)
    }
  }, [])

  const isEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }

  const handleInputChange = (value, contactType) => {
    if (contactType === 'whatsapp') {
      value = value.replace(/[^0-9]/g, '')
    }
    const strippedValue = value.replace(prefixes[contactType], '')
    let errorMessage = ''

    switch (contactType) {
      case 'instagram':
        const instagramRegex = /^[a-z0-9_]{3,30}$/
        if (!instagramRegex.test(strippedValue)) {
          errorMessage =
            'Solo se permiten minúsculas, números y no más de 30 caracteres.'
        }
        break

      case 'whatsapp':
        const whatsappRegex = /^(?!15|0|54|549)([0-9]{10})$/
        if (!whatsappRegex.test(strippedValue)) {
          errorMessage =
            'El número de WhatsApp debe ser de 10 dígitos y no puede comenzar con 15, 0, 54 o 549.'
        }
        break

      case 'linkedin':
        const linkedinRegex = /^[a-z0-9-]{3,30}$/
        if (!linkedinRegex.test(strippedValue)) {
          errorMessage =
            'Solo se permiten minúsculas, números y no más de 30 caracteres.'
        }
        break

      case 'mail':
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/
        if (!emailRegex.test(strippedValue)) {
          errorMessage = 'El correo electrónico debe tener un formato válido.'
        }
        break

      default:
        break
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [contactType]: errorMessage || undefined,
    }))
    setSelectedContacts((prevState) => ({
      ...prevState,
      [contactType]: value,
    }))
  }

  const handleRemoveContact = (contactType) => {
    setSelectedContacts((prevState) => {
      const updatedContacts = { ...prevState }
      delete updatedContacts[contactType]
      return updatedContacts
    })
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors }
      delete updatedErrors[contactType]
      return updatedErrors
    })

    const restoredContact = initialStatus.find(
      (item) => item.value === contactType,
    )
    setStatus((prevStatus) => [...prevStatus, restoredContact])
  }

  const buttonStyle = [
    styles.openButton,
    {
      marginBottom: 25,
      opacity: Object.keys(selectedContacts).length !== 4 ? 1 : 0.5,
    },
  ]

  const isButtonDisabled =
    Object.values(selectedContacts).some((contact) => contact === '') ||
    Object.values(errors).some((error) => error !== undefined) ||
    isEqual(selectedContacts, originalContacts)

  const handleSubmit = () => {
    saveNewContactsMethods(selectedContacts)
    navigation.navigate(origin)
  }

  return (
    <>
      <TouchableOpacity
        style={buttonStyle}
        onPress={() => setShowModalSelect(true)}
        disabled={
          status.length === 0 || Object.keys(selectedContacts).length === 4
        }
      >
        <ImageBackground
          source={backgroundButtonImage}
          style={styles.buttonBackground}
          imageStyle={styles.imageBackgroundStyle}
        >
          <View style={styles.openButtonContent}>
            <Text style={styles.openButtonText}>Agregar</Text>
            <Image source={signoMas} style={styles.shareIcon} />
          </View>
        </ImageBackground>
      </TouchableOpacity>

      <ModalItems
        showModalSelect={showModalSelect}
        setShowModalSelect={setShowModalSelect}
        status={status}
        setStatus={setStatus}
        selectedContacts={selectedContacts}
        setSelectedContacts={setSelectedContacts}
      />

      <ScrollView
        style={styles.scrollView}
        showsVerticalScrollIndicator={false}
      >
        {Object.keys(selectedContacts).map((contactType) => {
          const contactItem = initialStatus.find(
            (item) => item.value === contactType,
          )

          const placeholders = {
            instagram: 'miusuario',
            whatsapp: 'Ej: 3515555555',
            linkedin: 'miusuario',
            mail: 'email@ejemplo.com',
          }

          return (
            <View key={contactType} style={styles.inputBox}>
              <View style={styles.inputLabelContainer}>
                <View style={styles.inputLabelIconText}>
                  {contactItem?.icon && (
                    <Image
                      source={contactItem.icon}
                      style={styles.contactIcon}
                    />
                  )}
                  <Text style={styles.inputLabel}>{contactItem?.label}</Text>
                </View>
                {/* {(contactType === 'instagram' ||
                  contactType === 'linkedin') && (
                  <Text style={styles.helper}>¿Cuál es mi usuario?</Text>
                )} */}
              </View>
              <View style={styles.inputContainer}>
                <View style={styles.inputAndErrorsBox}>
                  <View style={styles.inputContainer2}>
                    <View style={styles.inputWithIcon}>
                      <Text style={styles.prefix}>{prefixes[contactType]}</Text>
                      <TextInput
                        style={styles.input}
                        placeholder={placeholders[contactType]}
                        placeholderTextColor="darkgray"
                        value={selectedContacts[contactType]}
                        onChangeText={(value) =>
                          handleInputChange(value.toLowerCase(), contactType)
                        }
                        textAlign="center"
                        autoCapitalize="none"
                        maxLength={30}
                        inputMode={
                          contactType === 'whatsapp' ? 'numeric' : 'text'
                        }
                      />
                    </View>
                    <TouchableOpacity
                      style={styles.trashButton}
                      onPress={() => handleRemoveContact(contactType)}
                    >
                      <Image source={trashIcon} style={styles.trashIcon} />
                    </TouchableOpacity>
                  </View>
                  {errors[contactType] && (
                    <View style={styles.errorBox}>
                      <Text style={styles.errorText}>
                        {errors[contactType]}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            </View>
          )
        })}
        {Object.keys(selectedContacts).length > 0 && (
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              style={[
                styles.btn,
                isButtonDisabled ? styles.btnDisabled : styles.btnEnabled,
              ]}
              disabled={isButtonDisabled}
              onPress={handleSubmit}
            >
              <Text style={styles.btnText}>Guardar y volver</Text>
            </TouchableOpacity>
          </View>
        )}
      </ScrollView>
    </>
  )
}

export default SelectContactMethodType
