import React, { useState } from 'react';
import { View, Text } from 'react-native';
import styles from './DatePickerCalendarRegisterStyles';

const DatePickerCalendarRegister = ({
  userData,
  setUserData,
}) => {
  const initialDate = userData.dateOfBirth
    ? new Date(userData.dateOfBirth.split('/').reverse().join('-'))
    : new Date();

  const [date, setDate] = useState(initialDate);

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    const newDate = new Date(selectedDate);
    setDate(newDate);
    const formattedDate = formatDate(newDate);
    setUserData({
      ...userData,
      dateOfBirth: formattedDate,
    });
  };

  return (
    <View style={styles.datePickerContainer}>
      <View style={styles.datePickerInput}>
        <Text style={styles.datePickerLabel}>Fecha de nacimiento</Text>
        <View style={styles.datePickerDivider} />
        <input
          type="date"
          value={date.toISOString().split('T')[0]} // Formato ISO requerido por el input
          onChange={handleDateChange}
          style={styles.datePickerInputField}
        />
      </View>
    </View>
  );
};

export default DatePickerCalendarRegister;
