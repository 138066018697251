import React from 'react'
import { View, ScrollView } from 'react-native'
import {
  Carousel,
  InfoCard,
  ConfigButtons,
  UserDataInputs,
  ModalProfileSettings,
  Loader,
  InfoModal,
  ScreenLayout,
} from '@/components'
import styles from './MyProfileStyles'
import { useMyProfileController } from './controllers/MyProfileController'
import CustomRefreshControl from '@/components/CustomRefreshControl/CustomRefreshControl'

const MyProfile = () => {
  const {
    userData,
    setUserData,
    currentImageIndex,
    setCurrentImageIndex,
    editInfo,
    setEditInfo,
    openSettingsModal,
    setOpenSettingsModal,
    setInputChanged,
    isLoading,
    isOpenModal,
    setIsOpenModal,
    pickImage,
    deleteImage,
    saveModifiedInputs,
    cancelModifiedInputs,
    isKeyboardVisible,
    emptyImage,
    fetchUserData,
  } = useMyProfileController()

  return (
    <ScreenLayout headerTitle={'Mi perfil'}>
      {isLoading ? (
        <Loader />
      ) : (
        <ScrollView
          contentContainerStyle={styles.scrollViewContent}
          showsVerticalScrollIndicator={false}
          refreshControl={<CustomRefreshControl onRefresh={fetchUserData} />}
        >
          <View style={styles.content}>
            {!isKeyboardVisible && (
              <Carousel
                images={userData.profilePictures}
                editInfo={editInfo}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                pickImage={pickImage}
                deleteImage={deleteImage}
                emptyImage={emptyImage}
              />
            )}
            <ConfigButtons
              editInfo={editInfo}
              setEditInfo={setEditInfo}
              setOpenSettingsModal={setOpenSettingsModal}
              saveModifiedInputs={saveModifiedInputs}
              cancelModifiedInputs={cancelModifiedInputs}
            />
            {editInfo ? (
              <UserDataInputs
                userData={userData}
                setUserData={setUserData}
                setInputChanged={setInputChanged}
                isKeyboardVisible={isKeyboardVisible}
              />
            ) : (
              <>
                <View style={styles.cardContainer}>
                  <InfoCard userData={userData} />
                </View>
                <ModalProfileSettings
                  openSettingsModal={openSettingsModal}
                  setOpenSettingsModal={setOpenSettingsModal}
                />
              </>
            )}
          </View>
        </ScrollView>
      )}
      <InfoModal
        type={'emptyProfilePic'}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        functionality={pickImage}
      />
    </ScreenLayout>
  )
}

export default MyProfile
