// import { NODE_ENV } from '@env'
// const ENVIRONMENT: NODE_ENV || 'development',

const URL_API_VINCUFY_LOCAL = 'http://192.168.100.31:3001';
const URL_API_VINCUFY_LOCAL2 = 'http://localhost:3001';
const URL_API_VINCUFY_QA = 'https://datebackendpruebas.onrender.com';
const URL_API_VINCUFY_PROD = 'https://vincufybackendprod.onrender.com';


const config = {
    URL_API_VINCUFY: URL_API_VINCUFY_PROD
}

export default config
