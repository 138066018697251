import { StyleSheet } from 'react-native'
import { Image, Platform } from 'react-native'
import backgroundImage from '@/assets/Navbar/fondoNavbar.png'
import myProfile from '@/assets/Navbar/usuario.png'
import myEvent from '@/assets/Navbar/evento.png'
import myRelationships from '@/assets/Navbar/url.png'
import findPeople from '@/assets/Navbar/logoNav.png'

const styles = StyleSheet.create({
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  tabIcon: {
    marginTop: Platform.OS === 'ios' ? 10 : 0,
    height: 20,
    width: 20,
  },
})

const screenOptions = {
  tabBarShowLabel: false,
  tabBarStyle: {
    backgroundColor: 'transparent',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: Platform.OS === 'ios' ? 80 : 55,
    elevation: 0,
    borderTopWidth: 0,
  },
  headerShown: false,
  tabBarHideOnKeyboard: true,
  tabBarBackground: () => (
    <Image
      source={backgroundImage}
      style={styles.backgroundImage}
      resizeMode="cover"
    />
  ),
}

export {
  styles,
  screenOptions,
  myEvent,
  myProfile,
  myRelationships,
  findPeople,
}
