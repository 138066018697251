import { fetchStatus } from '@/models/fetch.model'
import {
  GET_BONDS,
  GET_BONDS_LOADING,
  GET_BONDS_ERROR,
  GET_BOND_REQUESTS,
  GET_BOND_REQUESTS_LOADING,
  GET_BOND_REQUESTS_ERROR,
  GET_BOND_REQUEST_RESPONSE,
} from '@/redux/actions/index'

const initialState = {
  bonds: undefined,
  bondsStatus: fetchStatus.TO_FETCH,
  bondsError: null,
  bondRequests: [],
  bondRequestsStatus: fetchStatus.TO_FETCH,
  bondRequestsError: null,
  bondRequestResponse: undefined,
}

const bondReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOND_REQUESTS_LOADING:
      return {
        ...state,
        bondsStatus: fetchStatus.LOADING,
        bondsError: null
      }
    case GET_BONDS:
      return {
        ...state,
        bonds: action.payload,
        bondsStatus: fetchStatus.SUCCESS,
        bondsError: null
      }
    case GET_BOND_REQUESTS_ERROR:
      return {
        ...state,
        bondsStatus: fetchStatus.ERROR,
        bondsError: action.payload.error,
      }
    case GET_BOND_REQUESTS_LOADING:
      return {
        ...state,
        bondRequestsStatus: fetchStatus.LOADING,
        bondRequestsError: null
      }
    case GET_BOND_REQUESTS:
      return {
        ...state,
        bondRequests: action.payload,
        bondRequestsStatus: fetchStatus.SUCCESS,
        bondRequestsError: null
      }
    case GET_BOND_REQUESTS_ERROR:
      return {
        ...state,
        bondRequestsStatus: fetchStatus.ERROR,
        bondRequestsError: action.payload.error,
      }
    case GET_BOND_REQUEST_RESPONSE:
      return {
        ...state,
        bondRequestResponse: action.payload,
      }
    default:
      return state
  }
}

export default bondReducer
