import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    width: 123,
    height: 123,
    margin: 2
  },
  image: {
    width: '100%',
    height: '100%',
  },
})
