import React, { useState } from 'react'
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Image,
  ImageBackground,
  ActivityIndicator,
} from 'react-native-web'
import { Link } from '@react-navigation/native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import styles from './LoginStyles'
import logoVincufy from '@/assets/Header/logoVincufy.png'
import backgroundImg from '@/assets/Auth/backgroundAuth.png'
import { useLoginController } from './controllers/useLoginController'
import { Password } from '@/components'

const Login = () => {
  const [input, setInput] = useState({ email: '', password: '' })
  const [formErrors, setFormErrors] = useState({})
  const [errorLoginMessage, setErrorLoginMessage] = useState('')
  const [isLoadingLogin, setIsLoadingLogin] = useState(false)
  const { loginUser, validateLoginForm } = useLoginController()

  const handleChange = (e, name) => {
    setInput({ ...input, [name]: e })
    setFormErrors(validateLoginForm(input))
  }

  const handleSubmit = async () => {
    try {
      setIsLoadingLogin(true)
      setErrorLoginMessage('')
      await loginUser(input)
    } catch (error) {
      console.warn(error)
      setErrorLoginMessage(error.message)
    } finally {
      setIsLoadingLogin(false)
    }
  }

  const loginBtnDisabled =
    !!formErrors.email ||
    !!formErrors.password ||
    input.email.length === 0 ||
    input.password.length === 0 ||
    isLoadingLogin

  return (
    <ImageBackground
      source={backgroundImg}
      style={styles.backgroundImage}
      resizeMode="cover"
    >
      <KeyboardAwareScrollView
        contentContainerStyle={styles.gral}
        keyboardShouldPersistTaps="handled"
      >
        <>
          <Image source={logoVincufy} style={styles.logoVincufy} />
          <View style={styles.inputDiv}>
            <TextInput
              value={input.email}
              inputMode="email"
              textContentType="emailAddress"
              placeholder="Ingresá tu mail"
              placeholderTextColor="rgb(96, 96, 96)"
              style={styles.input}
              onChangeText={(text) =>
                handleChange(text.toLocaleLowerCase(), 'email')
              }
              autoCapitalize="none"
              autoCorrect={false}
            />
            {formErrors.email && (
              <Text style={styles.error}>{formErrors.email}</Text>
            )}

            <Password
              value={input.password}
              onChange={(e) => handleChange(e, 'password')}
              hasError={formErrors.password}
              placeholder={'Ingresá tu contraseña'}
            />
            {formErrors.password && (
              <Text style={styles.error}>{formErrors.password}</Text>
            )}
          </View>

          <TouchableOpacity
            onPress={handleSubmit}
            style={styles.loginButton}
            disabled={loginBtnDisabled}
          >
            {isLoadingLogin ? (
              <ActivityIndicator size="small" color="#ffffff" />
            ) : (
              <Text style={styles.buttonText}>Iniciar sesión</Text>
            )}
          </TouchableOpacity>
          {errorLoginMessage && (
            <Text style={styles.error}>{errorLoginMessage}</Text>
          )}

          <View style={styles.registerDiv}>
            <Text style={styles.text}>¿Todavía no creaste tu perfil? </Text>
            <Link to="/Register">
              <Text style={styles.span}>Registrate</Text>
            </Link>
          </View>
        </>
      </KeyboardAwareScrollView>
    </ImageBackground>
  )
}

export default Login
