import React from 'react'
import { View, Image } from 'react-native'
import styles from './BondRequestsStyle'
import { ScreenLayout, PeopleList } from '@/components'
import { useBondRequestsController } from './controllers/useBondRequestsController'
import bondIcon from '@/assets/People/vinculo.png'
import { useNavigation } from '@react-navigation/native'

const BondRequests = () => {
  const navigation = useNavigation()
  const {
    formatBondRequest,
    bondRequestsLoading,
    getBondRequests,
  } = useBondRequestsController()

  const goToDetail = (guest) => {
    navigation.navigate('ProfileDetail', { guest })
  }

  return (
    <ScreenLayout headerTitle={'Mis solicitudes'} headerBackUrl="People">
      <PeopleList
        infoUsuarios={formatBondRequest ?? []}
        isLoading={bondRequestsLoading}
        isLoadingFetch={bondRequestsLoading}
        empty={'Aún no tenés solicitudes de vinculación'}
        onRefresh={getBondRequests}
        buttonAction={(guest) => goToDetail(guest)}
        button={
          <View style={styles.acceptBondRequestBtn}>
            <Image source={bondIcon} style={styles.acceptBondRequestBtnIcon} />
          </View>
        }
      />
    </ScreenLayout>
  )
}

export default BondRequests
