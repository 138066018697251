import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    backgroundColor: 'black',
    height: '100%',
  },
  inputGroup: {
    margin: 10,
    gap: 5,
  },
  bckgImg: {
    width: '100%',
    height: '100%',
    resizeMode: 'stretch',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 7,
  },
  inputFilledView: {
    marginBottom: 15,
  },
  inputFilled: {
    height: 40,
    justifyContent:'center',
    borderRadius: 5,
    paddingHorizontal: 10,
    color: 'white',
    fontWeight: '300',
  },
  input: {
    height: 40,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    color: 'white',
    fontWeight: '300',
  },
  text: {
    color: 'white',
  },
  label: {
    color: 'white',
    marginTop: 70,
    marginBottom: 5,
    marginLeft: 10,
  },
  enabledButton: {
    backgroundColor: '#9459CE',
    padding: 9,
    borderRadius: 5,
    width: '94%',
    alignItems: 'center',
    margin: 'auto',
    marginTop: 15,
  },
  disabledButton: {
    backgroundColor: 'rgb(30 30 30)',
    padding: 9,
    borderRadius: 5,
    width: '94%',
    alignItems: 'center',
    margin: 'auto',
    marginTop: 15,
  },
  inputRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 10,
  },
  inputContainer: {
    flex: 1,
  },
  areaCodeContainer: {
    flex: 1,
  },
  phoneContainer: {
    flex: 3,
  },
  code: {
    height: 40,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    color: 'white',
    fontWeight: '300',
  },
  phone: {
    height: 40,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    color: 'white',
    fontWeight: '300',
  },
  labelText: {
    color: 'white',
    marginBottom: 5,
    marginLeft: 3,
    fontWeight: '200',
  },
  errorText: {
    color: 'red',
    marginTop: 5,
    marginLeft: 2,
    fontSize: 12,
    fontWeight: '200',
  },
})
