import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  acceptBondRequestBtn: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    padding: 8,
    backgroundColor: '#8811c8',
    borderWidth: 1,
    borderColor: 'silver',
  },
  acceptBondRequestBtnIcon: {
    height: 15,
    width: 15,
  },
})
