import { UPDATE_NOTIFICATION_TOKEN, SET_DEVICE_NOTIFICATIONS_STATUS } from '../actions';
import axios from 'axios';
import config from '../../../config'


const URL_API_VINCUFY = config.URL_API_VINCUFY

export const setDeviceNotificationsStatus = (deviceNotificationsStatus) => async (dispatch) => {
    try {
        dispatch({
            type: SET_DEVICE_NOTIFICATIONS_STATUS,
            payload: { deviceNotificationsStatus },
        })
    } catch (error) {
        console.log("setDeviceNotificationsStatus error.", error.message)
    }
};

export const updateNotificationToken = (notificationToken) => async (dispatch, getState) => {
    try {
        const storedToken = localStorage.getItem('vincufyNotificationToken');
        
        if (storedToken !== notificationToken) {
            const userId = getState().auth.secureStore.userId;
            const response = await axios.put(`${URL_API_VINCUFY}/users/${userId}/updateNotificationToken`, {
                payload: { notificationToken },
            })
            
            if (response.data.success === false) throw new Error(response.data.message)
            
            localStorage.setItem('vincufyNotificationToken', response.data.notificationToken);
            console.log("Notification Token uploaded")
        }
        dispatch({
            type: UPDATE_NOTIFICATION_TOKEN,
            payload: { notificationToken },
        })
    } catch (error) {
        console.log("Notification Token upload error.", error.message)
        dispatch({
            type: UPDATE_NOTIFICATION_TOKEN,
            payload: error.message,
        })
    }
};