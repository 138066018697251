import React from 'react'
import { ScrollView, View, Text, Image, TouchableOpacity } from 'react-native'
import styles from './PeopleListStyles'
import CustomRefreshControl from '@/components/CustomRefreshControl/CustomRefreshControl'
import { useNavigation } from '@react-navigation/native'

const PeopleList = ({
  infoUsuarios,
  button,
  buttonAction,
  onRefresh,
  empty,
}) => {
  const navigation = useNavigation()

  const goToDetail = (guest) => {
    navigation.navigate('ProfileDetail', { guest: guest })
  }

  return (
    <ScrollView
      contentContainerStyle={styles.container}
      showsVerticalScrollIndicator={false}
      refreshControl={<CustomRefreshControl onRefresh={onRefresh} />}
    >
      {!infoUsuarios?.[0] ? (
        <View style={styles.emptyState}>
          <Text style={styles.text}>{empty}</Text>
        </View>
      ) : (
        infoUsuarios.map((usuario, index) => (
          <View style={styles.rowDiv} key={index}>
            <View style={styles.row}>
              <TouchableOpacity
                style={styles.imgDiv}
                onPress={() => goToDetail(usuario)}
              >
                <Image
                  source={{ uri: usuario.profilePictures[0] }}
                  style={styles.img}
                />
              </TouchableOpacity>
              <Text style={styles.username}>
                {usuario.name} {usuario.lastName}
              </Text>
              <TouchableOpacity
                style={styles.actionBtn}
                onPress={() => buttonAction(usuario)}
              >
                {button}
              </TouchableOpacity>
            </View>
            <View style={styles.line} />
          </View>
        ))
      )}
    </ScrollView>
  )
}

export default PeopleList
