import { Alert } from 'react-native'
import { editUser } from '@/redux/actions/userActions'

export const handleChange = (
  field,
  value,
  areaCode,
  setAreaCode,
  setErrors,
  setInput,
  phoneNumber,
  setPhoneNumber,
  item,
) => {
  if (item === 'mail' && field === 'email') {
    setInput((prev) => ({ ...prev, email: value }))
    const error = value
      ? validateEmail(value)
        ? ''
        : 'El formato correcto es correo@mail.com'
      : ''
    setErrors((prev) => {
      const newErrors = { ...prev, email: error }
      if (!error) delete newErrors.email
      return newErrors
    })
  } else if (item === 'teléfono') {
    if (field === 'areaCode') {
      const updatedAreaCode = value
      setAreaCode(updatedAreaCode)
      const phone = `${updatedAreaCode}${phoneNumber}`
      setInput((prev) => ({ ...prev, phone }))
      const error =
        updatedAreaCode.length < 3
          ? `${getSingularOrPlural(3 - updatedAreaCode.length, 'número')}`
          : ''
      setErrors((prev) => {
        const newErrors = { ...prev, areaCode: error }
        if (!error) delete newErrors.areaCode
        return newErrors
      })
    } else if (field === 'phoneNumber') {
      const updatedPhoneNumber = value
      setPhoneNumber(updatedPhoneNumber)
      const phone = `${areaCode}${updatedPhoneNumber}`
      setInput((prev) => ({ ...prev, phone }))
      const error =
        updatedPhoneNumber.length < 7
          ? `${getSingularOrPlural(7 - updatedPhoneNumber.length, 'número')}`
          : ''
      setErrors((prev) => {
        const newErrors = { ...prev, phoneNumber: error }
        if (!error) delete newErrors.phoneNumber
        return newErrors
      })
    }
  }
}

const getSingularOrPlural = (number, singularWord) => {
  return number === 1
    ? `Falta ${number} ${singularWord}`
    : `Faltan ${number} ${singularWord}s`
}

const validateEmail = (payload) => {
  const regexEmail = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/
  return regexEmail.test(payload)
}

export const handleSubmit = async (
  input,
  item,
  navigation,
  setIsLoading,
  userId,
  dispatch,
) => {
  setIsLoading(true)
  try {
    if (userId) {
      editUser(userId, input, dispatch)
    }
    setIsLoading(false)
    Alert.alert(`Cambiaste tu ${item} con éxito!`)
    navigation.navigate('MyProfile') //FALTA REDIRECCIONAR AL INICIO DE SESIÓN
  } catch {
    Alert.alert('Ups algo salió mal, intentalo de nuevo!')
  }
}

export const isDisabled = (item, input, errors, areaCode, phoneNumber) => {
  if (item === 'mail') {
    return !(input.email.length > 0 && Object.keys(errors).length === 0)
  }
  if (item === 'teléfono') {
    return !(
      areaCode.length >= 3 &&
      phoneNumber.length >= 7 &&
      Object.keys(errors).length === 0
    )
  }
  return false
}
