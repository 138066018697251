import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  modalContent: {
    backgroundColor: 'black',
    borderRadius: 5,
    padding: 20,
    width: '90%',
    maxHeight: '80%',
    borderColor: '#ccc',
    borderWidth: 0.5,
    overflow: 'hidden',
  },
  statusItem: {
    padding: 15,
    borderRadius: 5,
    borderBottomColor: '#ccc',
  },
  itemContent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    width: 120,
  },
  itemContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  statusText: {
    color: 'white',
    fontSize: 16,
    marginLeft: 10,
    fontWeight: '300',
  },
  icon: {
    width: 24,
    height: 24,
    alignSelf: 'center',
  },
})
