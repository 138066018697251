import { EDIT_USER, GET_USER, GET_USER_EVENTS } from './index'
import axios from 'axios'
import config from '../../../config'

const URL_API_VINCUFY = config.URL_API_VINCUFY

// export const createUser = async (dispatch, email, password, events) => {
//   try {
//     const create = await axios.post(`${URL_API_VINCULANDO}/register`, {
//       email: email,
//       password: password,
//     })
//     dispatch({
//       type: CREATE_USER,
//       payload: create.data,
//     })
//     localStorage.setItem('idUsuario', create.data.message.userId)
//   } catch (error) {
//     dispatch({
//       type: CREATE_USER,
//       payload: error,
//     })
//     console.log(error, ' ERRORR')
//   }
// }

export const editUser = async (id, payload, dispatch) => {
  try {
    const edit = await axios.put(`${URL_API_VINCUFY}/users/${id}`, {
      payload,
    })
    dispatch({
      type: EDIT_USER,
      payload: edit.data,
    })
  } catch (error) {
    console.warn(error?.message)
  }
}

export const getUser = async (userId, dispatch) => {
  try {
    const user = await axios.get(`${URL_API_VINCUFY}/users/${userId}`)
    dispatch({
      type: GET_USER,
      payload: user.data,
    })
  } catch (error) {
    console.log('error en el catch', error)
  }
}

// export const getAllUsers = async (dispatch) => {
//   try {
//     const user = await axios.get(`${URL_API_VINCULANDO}/users`)
//     dispatch({
//       type: GET_ALL_USERS,
//       payload: user.data,
//     })
//   } catch (error) {
//     dispatch({
//       type: GET_ALL_USERS,
//       payload: error,
//     })
//   }
// }

export const handleUpload = async (fileUri, id, posicion, dispatch) => {
  const response = await fetch(fileUri)
  const blob = await response.blob()
  const file = new File(
    [blob],
    `photo_${Date.now()}.${blob.type.split('/')[1]}`,
    {
      type: blob.type,
    },
  )
  const formData = new FormData()
  formData.append('file', file)
  try {
    const response = await axios.post(
      `${URL_API_VINCUFY}/users/${id}/upload/${posicion}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    // dispatch({
    //   type: GET_USER,
    //   payload: response.data,
    // })
    return response.data
  } catch (error) {
    console.warn(error?.message)
  }
}

export const deleteProfilePicture = async (id, posicion) => {
  try {
    const response = await axios.delete(
      `${URL_API_VINCUFY}/users/${id}/deleteImage/${posicion}`,
    )
    return response.data
  } catch (error) {
    console.warn(error?.message)
  }
}

// export const sendEmailPassword = async (email) => {
//   try {
//     await axios.get(`${URL_API_VINCULANDO}/users/mail`, {
//       email: email,
//     });
//     // dispatch({
//     //   type: SEND_EMAIL,
//     //   payload: "Email enviado con éxito",
//     // });
//   } catch (error) {
//     console.log(error, "Error al enviar el email");
//   }
// };

// export const resetPassword = async (email, password) => {
//   try {
//     await axios.post(`${URL_API_VINCULANDO}/reset`, {
//       email: email,
//       password: password,
//     });
//   } catch (error) {
//     console.warn(error?.message)
//   }
// };
