import { Dimensions } from 'react-native'
import { StyleSheet, Platform } from 'react-native'

const { height } = Dimensions.get('window')

const getButtonBottomPosition = () => {
  if (height > 740) {
    return '50%'
  } else {
    return '47.5%'
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    position: 'absolute',
    bottom: getButtonBottomPosition(),
    left: '40%',
    transform: [{ translateX: -50 }],
    alignItems: 'center',
    zIndex: 10,
  },
  thermometerButton: {
    flexDirection: 'row',
    borderRadius: Platform.OS === 'ios' ? 25 : 37,
    borderBottomRightRadius: 45,
    borderTopRightRadius: 45,
    height: 58,
    width: 195,
    alignItems: 'center',
  },
  circle: {
    borderRadius: 40,
    height: 77,
    width: 77,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: -0.5,
  },
  icon: {
    width: 27,
    height: 27,
  },
  buttonText: {
    fontSize: 16,
    color: '#fff',
    flex: 1,
    textAlign: 'center',
    marginLeft: 8,
  },
})

export default styles
