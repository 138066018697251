import React from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  Modal,
  ImageBackground,
  Image,
  Linking,
} from 'react-native'
import backgroundImage from '../../../assets/Navbar/fondoNavbar.png'
import styles from './GetContactMethodModalStyles'
import linkedinIcon from '../../../assets/People/linkedin.png'
import mailIcon from '../../../assets/People/gmail.png'
import whatsappIcon from '../../../assets/People/whatsapp.png'
import instagramIcon from '../../../assets/People/instagram.png'

export default function GetContactMethodModal({
  guestInfo,
  isOpenContactModal,
  setIsOpenContactModal,
}) {
  
  const openLink = (type, value) => {
    if (value) {
      switch (type) {
        case 'Instagram':
        case 'LinkedIn':
          Linking.openURL(value)
          break
        case 'Mail':
          Linking.openURL(`mailto:${value}`)
          break
        case 'WhatsApp':
          Linking.openURL(`https://wa.me/${value}`)
          break
        default:
          break
      }
    }
  }

  const availableMethods = [
    {
      type: 'Instagram',
      value: guestInfo?.contactMethods?.instagram
        ? `https://instagram.com/${guestInfo.contactMethods.instagram}`
        : null,
      icon: instagramIcon,
    },
    {
      type: 'LinkedIn',
      value: guestInfo?.contactMethods?.linkedin
        ? `https://www.linkedin.com/in/${guestInfo.contactMethods.linkedin}`
        : null,
      icon: linkedinIcon,
    },
    {
      type: 'Mail',
      value: guestInfo?.contactMethods?.mail || null,
      icon: mailIcon,
    },
    {
      type: 'WhatsApp',
      value: guestInfo?.contactMethods?.whatsapp || null,
      icon: whatsappIcon,
    },
  ].filter((method) => method.value)

  return (
    <Modal transparent={true} visible={isOpenContactModal} animationType="fade">
      <View style={styles.background}>
        <ImageBackground
          source={backgroundImage}
          style={styles.modal}
          imageStyle={styles.backgroundImage}
        >
          <TouchableOpacity
            style={styles.closeButton}
            onPress={() => setIsOpenContactModal(false)}
          >
            <Text style={styles.closeButtonText}>×</Text>
          </TouchableOpacity>
          {availableMethods.length > 0 ? (
            <>
              <Text style={styles.text}>
                Contactate conmigo por:
              </Text>
              <View style={styles.contactMethodsContainer}>
                {availableMethods.map((method, index) => (
                  <TouchableOpacity
                    key={index}
                    style={styles.contactMethod}
                    onPress={() => openLink(method.type, method.value)}
                  >
                    <Image source={method.icon} style={styles.icon} />
                    <Text style={styles.methodText}>{method.type}</Text>
                  </TouchableOpacity>
                ))}
              </View>
            </>
          ) : (
            <Text style={styles.noContactText}>
              No hay métodos de contacto disponibles.
            </Text>
          )}
        </ImageBackground>
      </View>
    </Modal>
  )
}
