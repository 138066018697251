import { View } from 'react-native'
import styles from './ScreenLayoutStyles'
import Header from '../Header/Header'

export default function ScreenLayout({ headerTitle, headerBackUrl, children }) {
  return (
    <View style={styles.screenLayout}>
      <Header place={headerTitle} goBackUrl={headerBackUrl} />
      {children}
    </View>
  )
}
