import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  scrollView: {
    flexGrow: 1,
  },
  openButton: {
    width: 230,
    height: 45,
    borderRadius: 5,
    borderColor: 'darkgrey',
    borderWidth: 0.7,
    overflow: 'hidden',
  },
  openButtonContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 15,
    flex: 1,
  },
  openButtonText: {
    color: 'white',
    fontWeight: '500',
  },
  shareIcon: {
    width: 11,
    height: 11,
    marginTop: 3,
  },
  buttonBackground: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageBackgroundStyle: {
    width: '100%',
    height: '100%',
  },
  trashButton: {
    backgroundColor: 'grey',
    height: 50,
    width: 37,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  trashIcon: {
    height: 19,
    width: 19,
  },
  inputBox: {
    marginVertical: 15,
    padding: 5,
    width: 310,
    gap: 15,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  inputContainer2: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 300,
  },
  inputWithIcon: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: 'gray',
    borderWidth: 1,
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5,
    height: 50,
    flex: 1,
    paddingHorizontal: 10,
  },
  inputLabel: {
    fontSize: 16,
    color: 'white',
  },
  inputLabelIconText: {
    flexDirection: 'row',
    gap: 4,
  },
  helper: {
    fontSize: 13,
    color: 'white',
    textDecorationLine: 'underline',
  },
  input: {
    flex: 1,
    paddingLeft: 10,
    marginRight: 19,
    color: 'white',
    fontSize: 15,
  },
  prefix: {
    fontSize: 16,
    color: 'lightgrey',
    marginRight: 5,
    paddingVertical: 10,
  },
  inputLabelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 3,
  },
  contactIcon: {
    width: 20,
    height: 20,
    marginRight: 5,
  },
  buttonContainer: {
    marginTop: 40,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  btn: {
    backgroundColor: '#9459CE',
    paddingVertical: 10,
    borderRadius: 5,
    width: 200,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 10,
    marginBottom: 100
  },
  btnText: {
    color: 'white',
    fontSize: 14,
  },
  btnEnabled: {
    backgroundColor: '#9459CE',
  },
  btnDisabled: {
    backgroundColor: '#252525',
  },
  inputAndErrorsBox:{
    width: '100%', // O ajusta según lo necesario

  },
  errorBox:{
    flexWrap: 'wrap', // Permite que el texto haga wrap
    width: '100%', // O ajusta según lo necesario
  },
  errorText: {
    color: 'rgb(224, 20, 20)',
    lineHeight: 17,
    fontSize: 12,
    marginTop: 7,
  },
 
})
