import { useDispatch } from 'react-redux'
import { registerAction } from '@/redux/actions/authActions'
import { validateConfirmPassword, validateEmail, validatePassword } from '@/models/auth.model'

export const useRegisterController = () => {
  const dispatch = useDispatch()

  const registerUser = async (input) => {
    await registerAction(input.email, input.password, dispatch)
  }

  const validateRegisterForm = (input) => {
    let errors = {}
    errors.email = validateEmail(input.email)
    errors.password = validatePassword(input.password)
    errors.confirmPassword = validateConfirmPassword(input.confirmPassword, input.password)
    return errors
  }

  return { registerUser, validateRegisterForm }
}
