import React, { useEffect, useState } from 'react';
import styles from './CounterDownStyles';
import { View, Text } from 'react-native';

const CounterDown = ({ startDate }) => {
  const [timeRemaining, setTimeRemaining] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  
  useEffect(() => {
    const countdown = () => {
      const now = new Date();
      const endDate = new Date(startDate);
      const totalSeconds = Math.floor((endDate - now) / 1000);

      // Si el tiempo restante es menor o igual a cero, detener el contador
      if (totalSeconds <= 0) {
        setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return; // Salir de la función
      }

      const days = Math.floor(totalSeconds / 86400);
      const hours = Math.floor((totalSeconds % 86400) / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      setTimeRemaining({ days, hours, minutes, seconds });
    };

    const interval = setInterval(countdown, 1000);
    countdown(); // Llamada inicial para establecer el estado inmediatamente

    return () => clearInterval(interval); // Limpiar el intervalo al desmontar
  }, [startDate]);

  // Función para formatear los números a dos dígitos
  const formatNumber = (num) => String(num).padStart(2, '0');

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Apertura de sala virtual</Text>
      <View style={styles.box}>
        <View style={styles.timeContainer}>
          <Text style={styles.time}>
            {formatNumber(timeRemaining.days)} <Text style={styles.label}>Días</Text>
          </Text>
          <Text style={styles.time}>
            {formatNumber(timeRemaining.hours)} <Text style={styles.label}>Horas</Text>
          </Text>
          <Text style={styles.time}>
            {formatNumber(timeRemaining.minutes)} <Text style={styles.label}>Minutos</Text>
          </Text>
          <Text style={styles.time}>
            {formatNumber(timeRemaining.seconds)} <Text style={styles.label}>Segundos</Text>
          </Text>
        </View>
      </View>
    </View>
  );
};

export default CounterDown;
