import { deviceNotificationsStatuses } from '@/utils/notification.web'
import {
  GET_USER,
  EDIT_USER,
  GET_USER_EVENTS,
  GET_USER_EVENTS_TICKETS,
  SET_DEVICE_NOTIFICATIONS_STATUS,
  UPDATE_NOTIFICATION_TOKEN,
} from '@/redux/actions/index'

const initialState = {
  userInfo: undefined,
  userEvents: [],
  userEventsTickets: undefined,
  deviceNotificationsStatus: deviceNotificationsStatuses.KNOWN
}
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        userInfo: action.payload,
      }
    case EDIT_USER:
      return {
        ...state,
        userInfo: action.payload,
      }
    case GET_USER_EVENTS_TICKETS:
      return {
        ...state,
        userEventsTickets: action.payload,
      }
    case SET_DEVICE_NOTIFICATIONS_STATUS:
      return {
        ...state,
        deviceNotificationsStatus: action.payload?.deviceNotificationsStatus,
      }
    case UPDATE_NOTIFICATION_TOKEN:
      return {
        ...state,
        userInfo: {
          ...(state.userInfo || {}),
          notificationToken: action.payload.notificationToken
        },
      }
    default:
      return state
  }
}

export default userReducer
