import { combineReducers } from 'redux'
import userReducer from './userReducer'
import eventReducer from './eventReducer'
import authReducer from './authReducer'
import bondReducer from './bondReducer'

const rootReducer = combineReducers({
  user: userReducer,
  event: eventReducer,
  auth: authReducer,
  bond: bondReducer,
})

export default rootReducer
