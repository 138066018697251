import { useSelector, useDispatch } from 'react-redux'
import { getBondsAction } from '@/redux/actions/bondActions'
import { useCallback } from 'react'
import { fetchStatus } from '@/models/fetch.model'
import { useFocusEffect } from '@react-navigation/native'

export const useBondsController = () => {
  const dispatch = useDispatch()
  const userId = useSelector((state) => state.auth.secureStore.userId)
  const bonds = useSelector((state) => state.bond.bonds ?? [])
  const bondsStatus = useSelector((state) => state.bond.bondsStatus)
  const bondsLoading =
    bondsStatus === fetchStatus.LOADING || bondsStatus === fetchStatus.TO_FETCH

  const getBonds = () => {
    getBondsAction(userId, dispatch)
  }

  useFocusEffect(
    useCallback(() => {
      getBonds()
    }, []),
  )

  return { bonds, bondsLoading, getBonds }
}
