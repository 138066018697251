import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    paddingHorizontal: 10,
    height: 40
  },
  placeholderText: {
    color: 'white',
    marginRight: 10,
    fontWeight: '300'
  },
  searchIconContainer: {
    padding: 5,
    marginLeft: 'auto',
  },
  searchIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
    tintColor: '#ccc',
  },
});
