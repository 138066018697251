import { useDispatch } from 'react-redux'
import { loginAction } from '@/redux/actions/authActions'
import { validateEmail, validatePassword } from '@/models/auth.model'

export const useLoginController = () => {
  const dispatch = useDispatch()

  const loginUser = async (input) => {
    await loginAction(input.email, input.password, dispatch)
  }

  const validateLoginForm = (input) => {
    let errors = {}
    errors.email = validateEmail(input.email)
    errors.password = validatePassword(input.password)
    return errors
  }

  return { loginUser, validateLoginForm }
}
