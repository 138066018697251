import React from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  Modal,
  ImageBackground,
} from 'react-native'
import backgroundImage from '../../../assets/Navbar/fondoNavbar.png'
import styles from './SetContactMethodModalStyles'
import { useNavigation } from '@react-navigation/native'

export default function SetContactMethodModal({
  isOpenContactModal,
  setIsOpenContactModal,
}) {
  const navigation = useNavigation()

  const goToSetContactMethodsScreen = () => {
    navigation.navigate('ContactMethodScreen', {
      origin: 'People',
    })
    setIsOpenContactModal(false)
  }

  return (
    <Modal transparent={true} visible={isOpenContactModal} animationType="fade">
      <View style={styles.background}>
        <ImageBackground
          source={backgroundImage}
          style={styles.modal}
          imageStyle={styles.backgroundImage}
        >
          <Text style={styles.text}>
            Upss! primero agreguemos un método de contacto para que tus nuevos
            vínculos puedan hablarte por ahí.
          </Text>
          <View>
            <TouchableOpacity
              style={styles.btn}
              onPress={goToSetContactMethodsScreen}
            >
              <Text style={styles.btnText}>Cargar método de contacto</Text>
            </TouchableOpacity>
          </View>
        </ImageBackground>
      </View>
    </Modal>
  )
}
