import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  inputGroup: {
    width: '100%',
    gap: 15,
  },
  inputSmallGroup: {
    flexDirection: 'row',
    gap: 15,
  },
  inputSmall: {
    height: 40,
    width:100,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    flex: 2,
    color: 'white',
    fontWeight: '300',
  },
  inputDescription: {
    height: 110,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 10,
    color: 'white',
    fontWeight: '300',
    verticalAlign: 'top',
  },
  buttonInput: {
    height: 40,
    minHeight: 40,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    flex: 2,
    color: 'white',
    fontWeight: '300',
    justifyContent: 'center',
  },
  buttonText: {
    color: 'white',
  },
})
