import React, { useEffect } from 'react'
import { View, Animated, Easing, ActivityIndicator } from 'react-native'
import styles from './LoaderStyles'

const Loader = () => {
  // const animation = new Animated.Value(0);

  // useEffect(() => {
  //   Animated.loop(
  //     Animated.sequence([
  //       Animated.timing(animation, {
  //         toValue: 1,
  //         duration: 2500,
  //         easing: Easing.linear,
  //         useNativeDriver: false,
  //       }),
  //       Animated.timing(animation, {
  //         toValue: 0,
  //         duration: 2500,
  //         easing: Easing.linear,
  //         useNativeDriver: false,
  //       }),
  //     ])
  //   ).start();
  // }, [animation]);

  // const animatedOpacity = animation.interpolate({
  //   inputRange: [0, 0.5, 1],
  //   outputRange: [0, 1, 0],
  // });

  return (
    <View style={styles.overlay}>
      <ActivityIndicator size="large" color="#ffffff" />
      {/* <View style={styles.loaderContainer}>
        <Animated.View
          style={[
            styles.diamond,
            {
              borderColor: '#DD65FB',
              opacity: animatedOpacity,
              zIndex: 0,
            },
          ]}
        />
        <Animated.View
          style={[
            styles.diamond,
            {
              borderColor: '#8811C8',
              opacity: animatedOpacity,
              position: 'absolute',
              left: 25,
              zIndex: 1,
            },
          ]}
        />
      </View> */}
    </View>
  )
}

export default Loader
