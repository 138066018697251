import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  modalContent: {
    backgroundColor: 'black',
    borderRadius: 5,
    padding: 20,
    width: '90%',
    maxHeight: '80%',
    borderColor: '#ccc',
    borderWidth: 0.5,
    marginTop: '90%',
  },
  genderItem: {
    padding: 5,
    borderRadius: 5,
    height: 40,
    alignItems:'center',
    justifyContent:'center'
  },
  genderText: {
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    fontWeight: '300',
  },
  openButton: {
    backgroundColor: '#bfbfbf', // Color de fondo del input
    borderRadius: 10,           // Radio de borde
    width: 343,                 // Ancho del input
    height: 40,                 // Altura del input
    justifyContent: 'center',   // Centrar verticalmente el texto
    alignItems: 'center',       // Centrar horizontalmente el texto
    marginTop: 30,              // Margen superior
    borderColor: '#ccc',        // Color del borde
    borderWidth: 1,             // Ancho del borde
  },
  openButtonText: {
    color: 'black',             // Color del texto
    fontSize: 14,               // Tamaño del texto
    fontWeight: '600',          // Peso del texto
    textAlign: 'center',        // Alineación centrada del texto
  },
})
