import React from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { Image } from 'react-native'
import {
  styles,
  screenOptions,
  myProfile,
  myRelationships,
  findPeople,
} from './MyTabsStyles'
import { MyBonds } from '@/views'
import SettingsStack from '../Stacks/SettingsStack'
import PeopleStack from '../Stacks/PeopleStack'

const Tab = createBottomTabNavigator()
const getTabIconStyle = (focused) => [
  styles.tabIcon,
  { tintColor: focused ? '#f7c040' : '#ffffff' },
]

const MyTabs = () => {
  return (
    <Tab.Navigator initialRouteName="PeopleStack" screenOptions={screenOptions}>
      <Tab.Screen
        name="PeopleStack"
        component={PeopleStack}
        options={{
          tabBarIcon: ({ focused }) => (
            <Image source={findPeople} style={getTabIconStyle(focused)} />
          ),
        }}
      />
      <Tab.Screen
        name="MyBondsStack"
        component={MyBonds}
        options={{
          tabBarIcon: ({ focused }) => (
            <Image source={myRelationships} style={getTabIconStyle(focused)} />
          ),
        }}
      />
      {/* <Tab.Screen
        name="MyEventsStack"
        component={EventsStack}
        options={{
          tabBarIcon: () => <Image source={myEvent} style={getTabIconStyle(focused)} />,
        }}
      /> */}
      <Tab.Screen
        name="MyProfileStack"
        component={SettingsStack}
        options={{
          tabBarIcon: ({ focused }) => (
            <Image source={myProfile} style={getTabIconStyle(focused)} />
          ),
        }}
      />
    </Tab.Navigator>
  )
}

export default MyTabs
