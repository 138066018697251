import React, { useState } from 'react'
import {
  View,
  TextInput,
  ScrollView,
  TouchableOpacity,
  Text,
} from 'react-native'
import styles from './UserDataInputsStyles'
import ButtonSearch from '../SearchLocation/ButtonSearch/ButtonSearch'
import DatePickerCalendar from '../DatePickerCalendar/DatePickerCalendar'
import SelectGender from '../SelectGender/SelectGender'
import SelectRelationStatus from '../SelectRelationStatus/SelectRelationStatus'
import { useNavigation } from '@react-navigation/native'

const UserDataInputs = ({
  userData,
  setUserData,
  setInputChanged,
  isKeyboardVisible,
}) => {
  const navigation = useNavigation()
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [openSearchScreen, setOpenSearchScreen] = useState(false)
  const [selectGenderOpen, setSelectGenderOpen] = useState(false)
  const [selectRelationStatusOpen, setSelectRelationStatusOpen] =
    useState(false)

  const handleChange = (field, value) => {
    setInputChanged(true)
    setUserData({
      ...userData,
      [field]: value,
    })
  }

  const goToSetContactMethodsScreen = () => {
    navigation.navigate('ContactMethodScreen', {
      origin: 'MyProfile',
    })
  }

  return (
    <ScrollView
      contentContainerStyle={styles.container}
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps="always"
    >
      <View style={styles.inputGroup}>
        <View style={styles.inputSmallGroup}>
          <TextInput
            style={styles.inputSmall}
            value={userData.name}
            onChangeText={(value) => handleChange('name', value)}
            placeholder="Nombre"
            placeholderTextColor="white"
            maxLength={11}
          />
          <TextInput
            style={styles.inputSmall}
            value={userData.lastName}
            onChangeText={(value) => handleChange('lastName', value)}
            placeholder="Apellido"
            placeholderTextColor="white"
            maxLength={20}
          />
        </View>
        <TextInput
          style={styles.inputDescription}
          value={userData.description}
          onChangeText={(value) => handleChange('description', value)}
          placeholder="Descripción"
          placeholderTextColor="white"
          multiline={true}
          numberOfLines={5}
          maxLength={230}
        />
        <TouchableOpacity
          style={styles.buttonInput}
          onPress={goToSetContactMethodsScreen}
        >
          <Text style={styles.buttonText}>Métodos de contacto</Text>
        </TouchableOpacity>
        {/* <DatePickerCalendar
          showDatePicker={showDatePicker}
          setShowDatePicker={setShowDatePicker}
          userData={userData}
          setUserData={setUserData}
        /> */}
        {/* <ButtonSearch
          openSearchScreen={openSearchScreen}
          setOpenSearchScreen={setOpenSearchScreen}
          userData={userData}
          setUserData={setUserData}
        /> */}
        <SelectGender
          userData={userData}
          setUserData={setUserData}
          visible={selectGenderOpen}
          setSelectGenderOpen={setSelectGenderOpen}
        />
        {/* <SelectRelationStatus
          userData={userData}
          setUserData={setUserData}
          visible={selectRelationStatusOpen}
          setSelectRelationStatusOpen={setSelectRelationStatusOpen}
          isKeyboardVisible={isKeyboardVisible}
        /> */}
      </View>
    </ScrollView>
  )
}

export default UserDataInputs
