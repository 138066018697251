import React, { useState } from 'react'
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Image,
  // Modal,
  ImageBackground,
  ActivityIndicator,
} from 'react-native'
import { Link } from '@react-navigation/native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import styles from './RegisterStyles'
import logoVincufy from '@/assets/Header/logoHeaderVincufy.png'
import backgroundImg from '@/assets/Auth/backgroundAuth.png'
import { useRegisterController } from './controllers/useRegisterController'
import { Password } from '@/components'

const Register = () => {
  const [input, setInput] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  })
  const [formErrors, setFormErrors] = useState({})
  const [errorRegisterMessage, setErrorRegisterMessage] = useState('')
  const [isLoadingRegister, setIsLoadingRegister] = useState(false)
  const { registerUser, validateRegisterForm } = useRegisterController()

  const handleChange = (e, name) => {
    const newInput = { ...input, [name]: e }
    setInput(newInput)
    setFormErrors(validateRegisterForm(newInput))
  }

  const handleSubmit = async () => {
    try {
      setIsLoadingRegister(true)
      setErrorRegisterMessage('')
      await registerUser(input)
    } catch (error) {
      console.warn(error)
      setErrorRegisterMessage(error.message)
    } finally {
      setIsLoadingRegister(false)
    }
  }
  const registerBtnDisabled =
    !!formErrors.email ||
    !!formErrors.password ||
    !!formErrors.confirmPassword ||
    input.email.length === 0 ||
    input.password.length === 0 ||
    input.confirmPassword.length === 0 ||
    isLoadingRegister

  return (
    <ImageBackground
      source={backgroundImg}
      style={styles.backgroundImage}
      resizeMode="cover"
    >
      <KeyboardAwareScrollView
        contentContainerStyle={styles.gral}
        keyboardShouldPersistTaps="handled"
      >
        <Image source={logoVincufy} style={styles.logoVincufy} />
        <View style={styles.inputDiv}>
          <TextInput
            value={input.email}
            inputMode="email"
            textContentType="emailAddress"
            placeholder="Ingresá tu mail"
            placeholderTextColor="rgb(96, 96, 96)"
            style={styles.input}
            onChangeText={(text) =>
              handleChange(text.toLocaleLowerCase(), 'email')
            }
            autoCapitalize="none"
            autoCorrect={false}
          />
          {formErrors.email && (
            <Text style={styles.error}>{formErrors.email}</Text>
          )}

          {/* Contenedor del campo de contraseña */}
          <Password
            value={input.password}
            onChange={(e) => handleChange(e, 'password')}
            hasError={formErrors.password}
            placeholder={'Ingresá tu contraseña'}
          />
          {formErrors.password && (
            <Text style={styles.error}>{formErrors.password}</Text>
          )}

          <Password
            value={input.confirmPassword}
            onChange={(e) => handleChange(e, 'confirmPassword')}
            hasError={formErrors.confirmPassword}
            placeholder={'Confirmá tu contraseña'}
          />
          {formErrors.confirmPassword && (
            <Text style={styles.error}>{formErrors.confirmPassword}</Text>
          )}
        </View>

        <TouchableOpacity
          onPress={handleSubmit}
          style={styles.registerButton}
          disabled={registerBtnDisabled}
        >
          {isLoadingRegister ? (
            <ActivityIndicator size="small" color="#ffffff" />
          ) : (
            <Text style={styles.buttonText}>Continuar</Text>
          )}
        </TouchableOpacity>
        {errorRegisterMessage && (
          <Text style={styles.error}>{errorRegisterMessage}</Text>
        )}

        <View style={styles.registerDiv}>
          <Text style={styles.text}>¿Ya tenés una cuenta?</Text>
          <Link to="/Login">
            <Text style={styles.span}>Iniciá sesión</Text>
          </Link>
        </View>

        {/* Modal para mostrar mensajes de error */}
        {/* <Modal
          visible={!!errorRegisterMessage}
          transparent={true}
          animationType="slide"
          onRequestClose={() => setIsOpenInfo(false)}
        >
          <View style={styles.modalContainer}>
            <Text>{errorRegisterMessage}</Text>
          </View>
        </Modal> */}
      </KeyboardAwareScrollView>
    </ImageBackground>
  )
}

export default Register
