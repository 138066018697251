import { StyleSheet } from 'react-native-web'

export default StyleSheet.create({
  backgroundImage: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    overflow: 'hidden',
  },
  gral: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
    width: '100%',
    height: '100%',
    maxWidth: '100vw',
  },
  logoVincufy: {
    marginTop: '5%',
    height: 120,
    width: '100%', // Asegúrate de definir el ancho
    resizeMode: 'contain', // o 'cover' dependiendo de tus necesidades
  },
  inputDiv: {
    marginTop: 40,
    marginBottom: '4%',
    width: '100%',
    alignItems: 'center',
  },
  input: {
    backgroundColor: '#bfbfbf',
    borderRadius: 10,
    width: '100%', // Ocupa el 100% del contenedor padre
    maxWidth: 343, // Máximo de 343px
    height: 40,
    fontSize: 14,
    textAlign: 'center',
    marginTop: 30,
    fontWeight: '600',
    color: 'black',
  },
  loginButton: {
    width: '100%',
    maxWidth: 343,
    height: 45,
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    fontSize: 15,
    marginTop: 5,
  },
  error: {
    color: 'rgb(215, 64, 64)',
    marginTop: 8,
    marginBottom: -10,
  },
  registerDiv: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(197, 197, 197, 0.116)',
    height: 35,
    marginTop: '14%',
    paddingHorizontal: 20,
    borderRadius: 8,
    fontSize: 18,
    flexDirection: 'row',
  },
  text: {
    color: 'white',
  },
  span: {
    color: 'white',
    fontWeight: '500',
    textDecorationLine: 'underline',
    marginLeft: 5,
  },
  buttonText: {
    color: 'white',
  },
})
