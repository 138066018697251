//AUTH ACTIONS
export const REGISTER = 'REGISTER'
export const LOGIN = 'LOGIN'
export const LOG_OUT = 'LOG_OUT'
export const LOADING = 'LOADING'
export const GET_SECURE_STORE_INFO = 'GET_SECURE_STORE_INFO'

//USERS ACTIONS
export const EDIT_USER = 'EDIT_USER'
export const GET_USER = 'GET_USER'
export const SET_DEVICE_NOTIFICATIONS_STATUS = 'SET_DEVICE_NOTIFICATIONS_STATUS'
export const UPDATE_NOTIFICATION_TOKEN = 'UPDATE_NOTIFICATION_TOKEN'
export const SET_IS_USERINFO_EMPTY = 'SET_IS_USERINFO_EMPTY'
// export const GET_ALL_USERS = 'GET_ALL_USERS'
// export const DELETE_USER = 'DELETE_USER'
// export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'

//EVENTS ACTIONS
export const GET_EVENT = 'GET_EVENT'
export const GET_USER_EVENTS = 'GET_USER_EVENTS'
export const SET_SELECTED_EVENT_ID = 'SET_SELECTED_EVENT_ID'

export const GET_EVENT_GUESTS = 'GET_EVENT_GUESTS'
export const GET_EVENT_GUESTS_LOADING = 'GET_EVENT_GUESTS_LOADING'
export const GET_EVENT_GUESTS_ERROR = 'GET_EVENT_GUESTS_ERROR'
// export const CREATE_EVENT = 'CREATE_EVENT'
// export const EDIT_EVENT = 'EDIT_EVENT'
// export const GET_ALL_EVENTS = 'GET_ALL_EVENTS'
// export const GET_ADMINS = 'GET_ADMINS'
// export const GET_EVENT_ADMIN_INFO = 'GET_EVENT_ADMIN_INFO'
// export const ADD_ADMIN = 'ADD_ADMIN'
// export const ERASE_ADMIN = 'ERASE_ADMIN'

//TICKET ACTIONS
export const GET_USER_EVENTS_TICKETS = 'GET_USER_EVENTS_TICKETS'
// export const GET_ALL_DATE_TICKETS = 'GET_ALL_DATE_TICKETS'
// export const GET_COMPROBANTES = 'GET_COMPROBANTES'

//BONDS ACTIONS
export const GET_BONDS = 'GET_BONDS'
export const GET_BONDS_LOADING = 'GET_BONDS_LOADING'
export const GET_BONDS_ERROR = 'GET_BONDS_ERROR'
export const GET_BOND_REQUESTS = 'GET_BOND_REQUESTS'
export const GET_BOND_REQUESTS_LOADING = 'GET_BOND_REQUESTS_LOADING'
export const GET_BOND_REQUESTS_ERROR = 'GET_BOND_REQUESTS_ERROR'
export const GET_BOND_REQUEST_RESPONSE = 'GET_BOND_REQUEST_RESPONSE'
// export const RESPOND_BOND_REQUEST = 'RESPOND_BOND_REQUEST'
// export const SEND_BOND_REQUEST = 'SEND_BOND_REQUEST'
