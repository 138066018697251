export const changeDefaultPic = (guest) => {
  return {
    ...guest,
    profilePictures: guest.profilePictures[0].includes(
      '/public/imagen/defaultPic.png',
    )
      ? [
          'https://storage.googleapis.com/vincufy-34e1a.appspot.com/29e15344-f0cf-4f13-8a5c-f7caa29d3a54_photo_1729062411531.png',
        ]
      : guest.profilePictures,
  }
}

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

export const filterLoggedUserAndBonds = (guests, userId, bonds) => {
  return guests.filter(
    (guest) =>
      guest.userId !== userId &&
      !bonds.includes((bond) => bond.userId === guest.userId),
  )
}

export const guestAdapter = (guestDB) => {
  let adaptedGuest = guestDB

  adaptedGuest = changeDefaultPic(adaptedGuest)

  return adaptedGuest
}

export const guestsAdapter = (guestsDB, userId, bonds) => {
  let adaptedGuests = guestsDB.map((guestDB) => guestAdapter(guestDB))

  adaptedGuests = shuffleArray(adaptedGuests)
  adaptedGuests = filterLoggedUserAndBonds(adaptedGuests, userId, bonds)

  return adaptedGuests
}
