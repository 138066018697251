import {
  LOADING,
  REGISTER,
  LOGIN,
  LOG_OUT,
  GET_SECURE_STORE_INFO,
  SET_IS_USERINFO_EMPTY
} from '@/redux/actions/index'

const initialState = {
  secureStore: {
    token: null,
    userId: null,
    userEmail: null,
  },
  isLoading: true,
  register: null,
  login: null,
  isUserInfoEmpty: false
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case REGISTER:
      return {
        ...state,
        secureStore: action.payload.secureStore,
        register: { payload: action.payload, message: action.message },
        isLoading: false,
      }
    case LOGIN:
      return {
        ...state,
        secureStore: action.payload.secureStore,
        login: { payload: action.payload, message: action.message },
        isLoading: false,
      }
    case LOG_OUT:
      return {
        ...state,
        secureStore: initialState.secureStore,
        isLoading: false,
      }
    case GET_SECURE_STORE_INFO:
      return {
        ...state,
        secureStore: action.payload,
        isLoading: false,
      }
      case SET_IS_USERINFO_EMPTY:
        return{
          ...state,
          isUserInfoEmpty: action.payload
        }
    default:
      return state
  }
}

export default authReducer
