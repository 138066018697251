import React from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  Modal,
  ImageBackground,
} from 'react-native'
import backgroundImage from '@/assets/Navbar/fondoNavbar.png'
import styles from './SmallModalStyles'

export default function SmallModal({
  info,
  isOpenModal,
  setIsOpenModal,
  functionality,
}) {
  const isDeclined = () => {
    setIsOpenModal(false)
  }

  const isConfirmed = () => {
    setIsOpenModal(false)
    functionality()
  }

  return (
    <Modal transparent={true} visible={isOpenModal} animationType="fade">
      <View style={styles.background}>
        <ImageBackground
          source={backgroundImage}
          style={styles.modal}
          imageStyle={styles.backgroundImage}
        >
          <Text style={styles.text}>{info}</Text>
          <View style={styles.buttonContainer}>
            <TouchableOpacity onPress={isConfirmed} style={styles.btn}>
              <Text style={styles.btnText}>Aceptar</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={isDeclined} style={styles.btn}>
              <Text style={styles.btnText}>Cancelar</Text>
            </TouchableOpacity>
          </View>
        </ImageBackground>
      </View>
    </Modal>
  )
}
