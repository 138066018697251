import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSecureStoreDataAction } from '@/redux/actions/authActions'
import { getUser } from '@/redux/actions/userActions'
import {
  getEventGuestsAction,
  getUserEvents,
} from '@/redux/actions/eventActions'
import { getBondsAction } from '@/redux/actions/bondActions'
import { setDeviceNotificationsStatus } from '@/redux/thunks/user.thunk'
import { deviceNotificationsStatuses, shouldShowAllowNotificationsScreen } from '@/utils/notification.web'
import { fetchAndSaveToken } from './notifications/notifications.web'

export const useInitialLogic = () => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.secureStore.token)
  const userId = useSelector((state) => state.auth.secureStore.userId)
  const deviceNotificationsStatus = useSelector((state) => state.user.deviceNotificationsStatus)
  const showAllowNotificationsScreen = shouldShowAllowNotificationsScreen(deviceNotificationsStatus)
  const [isLoading, setIsLoading] = useState(true)
  const [isLogued, setIsLogued] = useState(false)
  const isFirstAppLoad = useRef(true)

  const recoverCredentials = async () => {
    try {
      console.log('Recovering Credentials...')

      if (token && userId) {
        console.log('Credentials found in App Storage')
        return { token, userId }
      }

      const secureStoreData = await getSecureStoreDataAction(dispatch)
      if (secureStoreData?.token && secureStoreData?.userId) {
        console.log('Credentials found in Device Storage')
        return {
          token: secureStoreData?.token,
          userId: secureStoreData?.userId,
        }
      }

      console.log('Credentials not Found.')
    } catch (error) {
      throw new Error('Error Recovering Credentials: ' + error)
    }
  }
  
  const checkIfAllowNotifications = async () => {
    // Step 1: Check if Notifications are supported in the browser.
    if (!("Notification" in window)) {
      console.info("This browser does not support desktop notification");
      dispatch(setDeviceNotificationsStatus(deviceNotificationsStatuses.BROWSER_NOT_SUPPORT))
      return
    }

    // Step 2: Check if permission is already granted.
    if (Notification.permission === deviceNotificationsStatuses.GRANTED) {
      dispatch(setDeviceNotificationsStatus(deviceNotificationsStatuses.GRANTED))
      
      const success = await fetchAndSaveToken(dispatch);
      if (!success) dispatch(setDeviceNotificationsStatus(deviceNotificationsStatuses.LOAD_TOKEN_FAILURE))
      return 
    }
    
    if (Notification.permission === deviceNotificationsStatuses.DENIED) {
      dispatch(setDeviceNotificationsStatus(deviceNotificationsStatuses.DENIED))
      return
    }
  }

  const fetchInitialData = async (currentUserId) => {
    try {
      console.log('Fetching Initial Data...')

      await getUser(currentUserId, dispatch)
      console.log('User Ready.')

      const bonds = await getBondsAction(currentUserId, dispatch)
      console.log('Bonds Ready.')

      const { selectedEventId } = await getUserEvents(currentUserId, dispatch)
      console.log('Events Ready.')

      if (selectedEventId) {
        await getEventGuestsAction(
          selectedEventId,
          1,
          currentUserId,
          bonds,
          dispatch,
        )
        console.log('Guets Ready.')
      }

      console.log('Fetch finished.')
    } catch (error) {
      throw new Error('Error Fetching Initial Data: ' + error)
    }
  }

  useEffect(() => {
    const firstAppLoad = async () => {
      try {
        setIsLoading(true)
        const credentials = await recoverCredentials()
        if (!credentials?.token || !credentials?.userId) return
        setIsLogued(true)
        checkIfAllowNotifications()
        await fetchInitialData(credentials.userId)
      } catch (error) {
        console.warn(error)
      } finally {
        setIsLoading(false)
      }
    }

    if (isFirstAppLoad.current) {
      isFirstAppLoad.current = false
      firstAppLoad()
    }
  }, [isFirstAppLoad])

  useEffect(() => {
    const handleFetchInitialData = async (currentUserId) => {
      try {
        setIsLoading(true)
        checkIfAllowNotifications()
        await fetchInitialData(currentUserId)
      } catch (error) {
        console.warn(error)
      } finally {
        setIsLoading(false)
      }
    }

    // Listen userId to update isLogued
    if (!userId && isLogued && !isLoading) {
      setIsLogued(false)
      return
    }

    if (userId && !isLogued && !isLoading) {
      setIsLogued(true)
      handleFetchInitialData(userId)
    }
  }, [isLogued, userId, isLoading])

  return { isLoading, isLogued, showAllowNotificationsScreen }
}
