import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  background: {
    marginTop: 10,
    flexDirection: 'row',
    height: 40,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bckgImg: {
    width: '100%',
    height: 65,
    resizeMode: 'cover',
  },
  bckgImgIos: {
    width: '100%',
    height: 100,
    resizeMode: 'cover',
  },
  logo: {
    width: 86,
    height: 20,
    marginLeft: 11,
    marginTop: 4
  },
})
