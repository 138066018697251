import { useEffect } from 'react'
import { fetchStatus } from '@/models/fetch.model'
import { useDispatch, useSelector } from 'react-redux'
import { getEventGuestsAction } from '@/redux/actions/eventActions'

export const useGuestsController = () => {
  const dispatch = useDispatch()
  const isLoadingAuth = useSelector((state) => state.auth.isLoading)
  const userId = useSelector((state) => state.auth.secureStore.userId)
  const bonds = useSelector((state) => state.bond.bonds)
  const eventId = useSelector((state) => state.event.selectedEventId)
  const userInfo = useSelector((state) => state.user.userInfo)
  const guests = useSelector(
    (state) => state.event.eventsGuests[eventId]?.data?.guests ?? [],
  )
  const loadedGuestsPages = useSelector(
    (state) => state.event.eventsGuests[eventId]?.data?.loadedGuestsPages ?? 0,
  )
  const numGuestsInDB = useSelector(
    (state) => state.event.eventsGuests[eventId]?.data?.numGuestsInDB ?? 0,
  )
  const guestsStatus = useSelector(
    (state) => state.event.eventsGuests[eventId]?.status,
  )
  const guestsError = useSelector(
    (state) => state.event.eventsGuests[eventId]?.error ?? null,
  )

  const guestsNotFetched = !guestsStatus
  const guestsLoading = guestsStatus === fetchStatus.LOADING
  const guestsSuccess = guestsStatus === fetchStatus.SUCCESS

  const isLoading = isLoadingAuth || (guestsLoading && !guests.length)
  const isLoadingFetch =
    guestsLoading && !!guests.length && loadedGuestsPages === 1
  const isLoadingMore =
    guestsLoading && !!guests.length && loadedGuestsPages !== 1

  const getEventGuests = (currentEventId, page) => {
    getEventGuestsAction(currentEventId, page, userId, bonds, dispatch)
  }

  useEffect(() => {
    if (eventId && guestsNotFetched) {
      getEventGuests(eventId, 1)
    }
  }, [eventId])

  const fetchMoreGuests = () => {
    if (guestsSuccess && guests.length < numGuestsInDB - 1) {
      getEventGuests(eventId, loadedGuestsPages + 1)
    }
  }

  const refreshGuests = () => {
    getEventGuests(eventId, 1)
  }

  return {
    guests,
    guestsError,
    userInfo,
    isLoading,
    isLoadingFetch,
    isLoadingMore,
    fetchMoreGuests,
    refreshGuests,
  }
}
