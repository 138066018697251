import React, { useState } from 'react'
import { View, Text, TextInput, Image, TouchableOpacity } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { Header } from '@/components'
import styles from './ChangeInfoSettingsStyles'
import backgroundImage from '@/assets/Navbar/fondoNavbar.png'
import { useNavigation } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import { useUserModel } from '../../models/userModel'
import { handleChange, handleSubmit, isDisabled } from './functions'

const ChangeInfoSettings = () => {
  const navigation = useNavigation()
  const route = useRoute()
  const dispatch = useDispatch()
  const { item } = route.params
  const { userId, userInfo, setIsLoading } = useUserModel()
  const { email, phone } = userInfo
  const [input, setInput] = useState(
    item === 'mail' ? { email: '' } : { phone: '' },
  )
  const [areaCode, setAreaCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [errors, setErrors] = useState({})

  return (
    <>
      <Header place={'Cambiar datos'} goBackUrl="MyProfile" />
      <View style={styles.container}>
        <Text style={styles.label}>Este es tu {item} actual</Text>
        <View style={styles.inputGroup}>
          <View style={styles.inputFilledView}>
            <Image source={backgroundImage} style={styles.bckgImg} />
            <View style={styles.inputFilled}>
              <Text style={styles.text}>{item === 'mail' ? email : phone}</Text>
            </View>
          </View>
          {item === 'mail' ? (
            <>
              <TextInput
                style={styles.input}
                value={input.email}
                onChangeText={(value) =>
                  handleChange(
                    'email',
                    value,
                    areaCode,
                    setAreaCode,
                    setErrors,
                    setInput,
                    phoneNumber,
                    setPhoneNumber,
                    item,
                  )
                }
                placeholder={`Escribí tu nuevo mail`}
                placeholderTextColor="white"
              />
              {errors.email ? (
                <Text style={styles.errorText}>{errors.email}</Text>
              ) : null}
            </>
          ) : (
            <View style={styles.inputRow}>
              <View style={[styles.inputContainer, styles.phoneContainer]}>
                <Text style={styles.labelText}>Cod. área</Text>
                <TextInput
                  style={styles.code}
                  value={areaCode}
                  onChangeText={(value) =>
                    handleChange(
                      'areaCode',
                      value,
                      areaCode,
                      setAreaCode,
                      setErrors,
                      setInput,
                      phoneNumber,
                      setPhoneNumber,
                      item,
                    )
                  }
                  placeholder="Ej: 011"
                  placeholderTextColor="white"
                  inputMode="numeric"
                  maxLength={3}
                />
                {errors.areaCode ? (
                  <Text style={[styles.errorText, styles.redText]}>
                    {errors.areaCode}
                  </Text>
                ) : null}
              </View>
              <View style={[styles.inputContainer, styles.phoneContainer]}>
                <Text style={styles.labelText}>Número sin el 15</Text>
                <TextInput
                  style={styles.phone}
                  value={phoneNumber}
                  onChangeText={(value) =>
                    handleChange(
                      'phoneNumber',
                      value,
                      areaCode,
                      setAreaCode,
                      setErrors,
                      setInput,
                      phoneNumber,
                      setPhoneNumber,
                      item,
                    )
                  }
                  placeholder="Ej: 2843532"
                  placeholderTextColor="white"
                  inputMode="numeric"
                  maxLength={7}
                />
                {errors.phoneNumber ? (
                  <Text style={styles.errorText}>{errors.phoneNumber}</Text>
                ) : null}
              </View>
            </View>
          )}
        </View>
        <TouchableOpacity
          style={
            isDisabled(item, input, errors, areaCode, phoneNumber)
              ? styles.disabledButton
              : styles.enabledButton
          }
          disabled={isDisabled(item, input, errors, areaCode, phoneNumber)}
          onPress={() =>
            handleSubmit(
              input,
              item,
              navigation,
              setIsLoading,
              userId,
              dispatch,
            )
          }
        >
          <Text style={{ color: 'white' }}>Cambiar</Text>
        </TouchableOpacity>
      </View>
    </>
  )
}

export default ChangeInfoSettings
