import React, { useState, useRef } from 'react'
import {
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  Dimensions,
} from 'react-native'
import styles from './CarouselStyles'
import changePic from '@/assets/Profile/changePic.png'
import deletePic from '@/assets/Profile/deletePic.png'
import SmallModal from '../Modals/SmallModal/SmallModal'

const Carousel = ({
  images,
  editInfo,
  currentImageIndex,
  setCurrentImageIndex,
  pickImage,
  deleteImage,
  emptyImage,
  origin,
}) => {
  const scrollViewRef = useRef(null)
  const [touchStartX, setTouchStartX] = useState(null)
  const [touchEndX, setTouchEndX] = useState(null)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleTouchStart = (e) => {
    const touchX = e.touches[0].clientX
    setTouchStartX(touchX)
  }

  const handleTouchMove = (e) => {
    const touchX = e.touches[0].clientX
    setTouchEndX(touchX)
  }

  const handleTouchEnd = () => {
    if (touchStartX && touchEndX) {
      const difference = touchStartX - touchEndX
      if (difference > 50 && currentImageIndex < images.length - 1) {
        setCurrentImageIndex(currentImageIndex + 1)
      } else if (difference < -50 && currentImageIndex > 0) {
        setCurrentImageIndex(currentImageIndex - 1)
      }
      scrollViewRef.current.scrollTo({
        x: currentImageIndex * Dimensions.get('window').width,
        y: 0,
        animated: true,
      })
    }
    setTouchStartX(null)
    setTouchEndX(null)
  }

  return (
    <View style={styles.carouselContainer}>
      <ScrollView
        ref={scrollViewRef}
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        scrollEventThrottle={200}
      >
        {images &&
          images.map((_, index) => (
            <TouchableOpacity
              key={index}
              style={styles.imageContainer}
              onPress={pickImage && pickImage}
              disabled={
                origin === 'profileDetail' ||
                !emptyImage(images[currentImageIndex])
              }
            >
              <Image source={images[currentImageIndex]} style={styles.image} />
              {editInfo && !emptyImage(images[currentImageIndex]) && (
                <View style={styles.buttonsContainer}>
                  <TouchableOpacity onPress={pickImage}>
                    <Image source={changePic} style={styles.editButton} />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => setIsOpenModal(true)}>
                    <Image source={deletePic} style={styles.editButton} />
                  </TouchableOpacity>
                </View>
              )}
            </TouchableOpacity>
          ))}
      </ScrollView>
      <View style={styles.indicatorContainer}>
        {images &&
          images.map((_, index) => (
            <View
              key={index}
              style={[
                styles.indicator,
                index === currentImageIndex && styles.activeIndicator,
              ]}
            />
          ))}
      </View>
      <SmallModal
        info={'¿Querés eliminar esta foto de perfil?'}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        functionality={deleteImage && deleteImage}
      />
    </View>
  )
}

export default Carousel
