import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  datePickerContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: 10,
  },
  datePickerInput: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 40,
    width: '100%',
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    backgroundColor: 'black',
  },
  datePickerLabel: {
    color: 'white',
    fontWeight: '300',
  },
  datePickerDivider: {
    height: '100%',
    width: 1.5,
    backgroundColor: 'grey',
    marginLeft: 40,
  },
  datePickerInputField: {
    width: 120,
    height: '100%',
    border: 'none',
    backgroundColor: 'transparent',
    color: 'white',
    fontSize: 14,
    fontWeight: '600',
    textAlign: 'center',
    cursor: 'pointer',
  },
  calendarIconContainer: {
    marginLeft: 10,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
  },
});
