import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 15,
  },
  button: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    borderWidth: 0.5,
    borderRadius: 7,
    borderColor: 'rgb(232, 232, 232)',
    padding: 5,
    width: 110,
    height: 30,
    marginTop: 20,
    marginBottom: 10,
  },
  button2:{
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    borderWidth: 0.5,
    borderRadius: 7,
    padding: 5,
    width: 110,
    height: 32,
    marginTop: 20,
    marginBottom: 10,
  },
  text: {
    color: 'white',
    fontSize: 13,
  },
  edit: {
    height: 16,
    width: 16,
    marginLeft: -10,
  },
  settings: {
    height: 16,
    width: 16,
  },
  bckgImg: {
    width: 110,
    height: 32,
    resizeMode: 'cover',
    position: 'absolute',
    top: 20,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 7,
  },
})
