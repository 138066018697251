import {
  LOADING,
  REGISTER,
  LOGIN,
  LOG_OUT,
  GET_SECURE_STORE_INFO,
} from './index'
import config from '../../../config'
import {
  saveSecureStoreData,
  deleteSecureStoreData,
  getSecureStoreData,
} from '@/models/auth.model'
import axios from 'axios'

const URL_API_VINCUFY = config.URL_API_VINCUFY

export const registerAction = async (email, password, dispatch) => {
  const response = await axios.post(`${URL_API_VINCUFY}/register`, {
    email,
    password,
  })
  if (response.data.success === false) throw new Error(response.data.message)
  saveSecureStoreData(response.data.token, response.data.id, email)
  dispatch({
    type: REGISTER,
    payload: {
      ...response.data,
      secureStore: {
        token: response.data.token,
        userId: response.data.id,
        userEmail: email,
      },
    },
    message: response.data.message,
  })
}

export const loginAction = async (email, password, dispatch) => {
  const response = await axios.post(`${URL_API_VINCUFY}/login`, {
    email,
    password,
  })
  if (response.data.success === false) throw new Error(response.data.message)
  saveSecureStoreData(response.data.token, response.data.id, email)
  dispatch({
    type: LOGIN,
    payload: {
      ...response.data,
      secureStore: {
        token: response.data.token,
        userId: response.data.id,
        userEmail: email,
      },
    },
    message: response.data.message,
  })
}

export const logoutAction = async (email, dispatch, navigation) => {
  await axios.post(`${URL_API_VINCUFY}/logout`, {
    email,
  })
  deleteSecureStoreData()
  dispatch({
    type: LOG_OUT,
  })
  navigation.navigate('Login')
}

export const getSecureStoreDataAction = async (dispatch) => {
  const secureStoreData = await getSecureStoreData()
  dispatch({
    type: GET_SECURE_STORE_INFO,
    payload: secureStoreData,
  })
  return secureStoreData
}

export const setLoading = (payload, dispatch) => {
  dispatch({
    type: LOADING,
    payload,
  })
}
