import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  searchIconContainer: {
    padding: 5,
  },
  searchIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
    tintColor: 'white',
  },
})

const googleStyles = {
  container: {
    flex: 0,
  },
  textInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'black',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    paddingHorizontal: 10,
  },
  textInput: {
    flex: 1,
    height: 35,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 3,
    paddingLeft: 8,
    color: 'white',
    fontWeight: '300',
    backgroundColor: 'transparent',
  },
  predefinedPlacesDescription: {
    color: 'white',
  },
}

export { styles, googleStyles }
