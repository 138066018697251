import { StyleSheet, Dimensions, Platform } from 'react-native'
const { width } = Dimensions.get('window')
const vw = (percentage) => {
  return (width * percentage) / 100
}

export default StyleSheet.create({
  container: {
    marginTop: 6,
  },
  box: {
    borderWidth: 0.5,
    borderRadius: 7,
    marginRight: 10,
    borderColor: 'rgb(232, 232, 232)',
    width: vw(38),
    maxWidth: 200,
    height: 28,
    backgroundColor: 'black',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    height: Platform.OS === 'ios' ? 16 : 14,
    width: 14,
    marginLeft: 13,
  },
  textAlign: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 1,
  },
  text: {
    color: 'white',
    justifyContent: 'center',
    fontSize: 12,
  },
})
