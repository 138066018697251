import {
  GET_BONDS,
  GET_BONDS_LOADING,
  GET_BONDS_ERROR,
  GET_BOND_REQUESTS,
  GET_BOND_REQUESTS_LOADING,
  GET_BOND_REQUESTS_ERROR,
  GET_BOND_REQUEST_RESPONSE,
} from './index'
import axios from 'axios'
import config from '../../../config'
import { bondRequestsAdapter, bondsAdapter } from '@/adapters/bond.adapter'

const URL_API_VINCUFY = config.URL_API_VINCUFY

//Mis vinculos
export const getBondsAction = async (userId, dispatch) => {
  try {
    dispatch({ type: GET_BONDS_LOADING })

    const bonds = await axios.get(`${URL_API_VINCUFY}/bonds/getBonds/${userId}`)
    const bondsAdapted = bondsAdapter(bonds.data)
    dispatch({
      type: GET_BONDS,
      payload: bondsAdapted,
    })
    return bondsAdapted
  } catch (error) {
    console.warn(error?.message)
    dispatch({
      type: GET_BONDS_ERROR,
      payload: {
        error: error?.message ?? '',
      },
    })
  }
}

//Enviar solicitud de vinculación
export const sendBondRequestAction = async (
  requesterId,
  receiverId,
  eventId,
) => {
  try {
    await axios.post(`${URL_API_VINCUFY}/bonds/bondRequests/request/`, {
      requesterId,
      receiverId,
      eventId,
    })
  } catch (error) {
    console.warn(error?.message)
  }
}

// Responder solicitud de vinculación
export const respondBondRequestAction = async (requestId, status) => {
  try {
    await axios.put(`${URL_API_VINCUFY}/bonds/bondRequests/respond/`, {
      requestId,
      status,
    })
  } catch (error) {
    console.warn(error?.message)
  }
}

// Solicitudes recibidas
export const getBondRequestsAction = async (userId, eventId, dispatch) => {
  try {
    dispatch({ type: GET_BOND_REQUESTS_LOADING })

    const bondRequests = await axios.get(
      `${URL_API_VINCUFY}/bonds/bondRequests/${userId}/${eventId}`,
    )
    dispatch({
      type: GET_BOND_REQUESTS,
      payload: bondRequestsAdapter(bondRequests.data),
    })
  } catch (error) {
    console.warn(error?.message)
    dispatch({
      type: GET_BOND_REQUESTS_ERROR,
      payload: {
        error: error?.message ?? '',
      },
    })
  }
}

// Respuesta/estado de una solicitud recibida o enviada
export const getBondRequestResponseAction = async (
  userId,
  guestId,
  eventId,
  dispatch,
) => {
  try {
    const bondRequestResponse = await axios.get(
      `${URL_API_VINCUFY}/bonds/checkBondRequestsStatus/${userId}/${guestId}/${eventId}`,
    )
    dispatch({
      type: GET_BOND_REQUEST_RESPONSE,
      payload: bondRequestResponse.data,
    })
  } catch (error) {
    console.warn(error?.message)
  }
}
