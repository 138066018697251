import React, { useState } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { Header } from '../../../../components'
import styles from './ContactMethodScreenStyles'
import { SelectContactMethodType } from '../../../../components'

const ContactMethodScreen = () => {
  const route = useRoute()
  const { origin } = route.params
  const [showModalSelect, setShowModalSelect] = useState(false)
  return (
    <>
      <Header place={'Mis redes'} goBackUrl={origin} />
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>
            Agregá tus métodos de contacto favoritos para que los nuevos
            vínculos puedan hablarte
          </Text>
          <SelectContactMethodType
            showModalSelect={showModalSelect}
            setShowModalSelect={setShowModalSelect}
            origin={origin}
          />
        </View>
      </View>
    </>
  )
}

export default ContactMethodScreen
