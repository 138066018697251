import logoVincufy from '@/assets/Header/logoHeaderVincufy.png'
import backgroundImage from '@/assets/Navbar/fondoNavbar.png'
import styles from './HeaderStyles'
import { View, Image, ImageBackground, Platform } from 'react-native'
import Section from './Section/Section'

const Header = ({ place, goBackUrl }) => {
  return (
    <>
      <ImageBackground
        source={backgroundImage}
        style={styles.bckgImg}
      >
        <View
          style={
            Platform.OS === 'ios' ? styles.backgroundIos : styles.background
          }
        >
          <Image
            source={logoVincufy}
            style={styles.logo}
            resizeMode="stretch"
          />
          <Section place={place} goBackUrl={goBackUrl} />
        </View>
      </ImageBackground>
    </>
  )
}

export default Header
