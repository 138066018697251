import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  content: {
    flex: 1,
  },
  scrollViewContent: {
    flex: 1,
    flexGrow: 1, // Asegura que el contenido se ajuste al ScrollView
    justifyContent: 'center', // Centra el contenido cuando no hay suficiente para hacer scroll
  },
  headerInLoader: {
    zIndex: 9999999,
  },
  cardContainer: {
    flex: 1,
  },
})
