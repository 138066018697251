import React, { useState } from 'react'
import { View, Text } from 'react-native'
import styles from './MyBondsStyles'
import { PeopleList, ScreenLayout } from '@/components'
import { useBondsController } from './controller/useBondsController'
// import { useNavigation } from '@react-navigation/native'
import { GetContactMethodModal } from '@/components'

const MyBonds = () => {
  // const navigation = useNavigation()
  const { bonds, bondsLoading, getBonds } = useBondsController()
  const [isOpenContactModal, setIsOpenContactModal] = useState(false)
  const [selectedGuest, setSelectedGuest] = useState({})

  const openModal = (guest) => {
    setSelectedGuest(guest)
    setIsOpenContactModal(true)
  }

  return (
    <ScreenLayout headerTitle={'Mis vínculos'}>
      <PeopleList
        infoUsuarios={bonds}
        isLoading={bondsLoading}
        isLoadingFetch={bondsLoading}
        empty={'Aún no te vinculaste con ninguna persona'}
        onRefresh={getBonds}
        buttonAction={(guest) => openModal(guest)}
        button={
          <View style={styles.contactBondBtn}>
            <Text style={styles.contactBondBtnLabel}>Contactar</Text>
          </View>
        }
      />
      <GetContactMethodModal
        guestInfo={selectedGuest}
        isOpenContactModal={isOpenContactModal}
        setIsOpenContactModal={setIsOpenContactModal}
      />
    </ScreenLayout>
  )
}

export default MyBonds
