import React from 'react'
import { View, Text, ScrollView } from 'react-native'
import styles from './PreVirtualRoomStyles'
import EventCard from './EventCard/EventCard'
import CounterDown from './CounterDown/CounterDown'

const PreVirtualRoom = ({ startDate, userEvents, selectedEvent }) => {
  //TODO:
  //Hacer un condicional para validar si tiene más de un evento próximo y mostrar un slider en 3D
  //centro de la tarjeta más grande, y los costados más pequeños para ir deslizando entre eventos y ver cuánto falta para sala
  if (!userEvents || userEvents.length === 0) {
    return (
      <View style={styles.emptycontainer}>
        <Text style={styles.title}>No tenés eventos próximos</Text>
      </View>
    )
  }

  return (
    <ScrollView
      contentContainerStyle={styles.container}
      showsVerticalScrollIndicator={false}
    >
      <Text style={styles.title}>Próximos eventos</Text>
      <EventCard eventInfo={selectedEvent} />
      <CounterDown startDate={startDate} />
    </ScrollView>
  )
}

export default PreVirtualRoom
