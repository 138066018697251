import React from 'react'
import {
  View,
  Text,
  Image,
  ImageBackground,
} from 'react-native'
import styles from './EventCardStyles'
import cardBackground from '@/assets/MyEvents/fondoCards.png'
import feria21 from '@/assets/MyEvents/feria21.jpg'

const EventCard = ({ eventInfo }) => {
  return (
    <View style={styles.container}>
      <ImageBackground source={cardBackground} style={styles.backgroundImage}>
        <View style={styles.flyerDiv}>
          <Image source={feria21} style={styles.flyerImage} />
        </View>
        <Text style={styles.title}>{eventInfo?.eventName}</Text>
        <Text style={styles.subtitle}>Siglo 21</Text>
      </ImageBackground>
    </View>
  )
}

export default EventCard
