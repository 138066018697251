import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Carousel, ScreenLayout, InfoCard } from '@/components'
import { useRoute } from '@react-navigation/native'
import styles from './ProfileDetailStyles'
import SetContactMethodModal from '@/components/Modals/SetContactMethodModal/SetContactMethodModal'
import ActionButton from '@/views/People/components/ActionButton/ActionButton'
import { getBondRequestResponseAction } from '@/redux/actions/bondActions'
import { useDispatch, useSelector } from 'react-redux'
import { useMyProfileController } from '../../../MyProfile/controllers/MyProfileController'

const ProfileDetail = () => {
  const route = useRoute()
  const dispatch = useDispatch()
  const { guest } = route.params
  const eventId = useSelector((state) => state.event.selectedEventId)
  const userId = useSelector((state) => state.auth.secureStore.userId)
  const { emptyImage } = useMyProfileController()
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [isOpenContactModal, setIsOpenContactModal] = useState(false)

  useEffect(() => {
    if (guest) {
      getBondRequestResponseAction(userId, guest.userId, eventId, dispatch)
    }
  }, [guest, eventId])

  return (
    <ScreenLayout headerTitle={'Vinculate'} headerBackUrl="People">
      <Carousel
        images={guest.profilePictures}
        currentImageIndex={currentImageIndex}
        setCurrentImageIndex={setCurrentImageIndex}
        origin={'profileDetail'}
        emptyImage={emptyImage}
      />
      <ActionButton guest={guest} />
      <View style={styles.cardContainer}>
        <InfoCard userData={guest} />
      </View>
      <SetContactMethodModal
        isOpenContactModal={isOpenContactModal}
        setIsOpenContactModal={setIsOpenContactModal}
      />
    </ScreenLayout>
  )
}

export default ProfileDetail
