import {
  // GET_ALL_DATE_TICKETS,
  // GET_COMPROBANTES,
  GET_USER_EVENTS_TICKETS,
} from './index'
import axios from 'axios'
import config from '../../../config'

const URL_API_VINCUFY = config.URL_API_VINCUFY
// export const getComprobantes = (eventId, userId, dispatch) => {
//     try {
//       const comprobantes = axios.get(
//         `${URL_API_VINCULANDO}/evento/${eventId}/comprobantes/${userId}`
//       );
//       dispatch({
//         type: GET_COMPROBANTES,
//         payload: comprobantes.data,
//       });
//     } catch {}
//   };

export const getUserEventTickets = async (eventId, userId, dispatch) => {
  // if (!clear) {
  try {
    const userEventTickets = await axios.get(
      `${URL_API_VINCUFY}/evento/${eventId}/comprobantes/${userId}`,
    )
    dispatch({
      type: GET_USER_EVENTS_TICKETS,
      payload: userEventTickets.data,
    })
    return userEventTickets.data
  } catch (error) {
    console.warn(error?.message)
  }
  // } else {
  //   dispatch({
  //     type: GET_USER_EVENT_TICKETS,
  //     payload: undefined,
  //   });
  // }
}

// export const createTickets = async (encryptedData) => {
//   try {
//     await axios.post(`${URL_API_VINCULANDO}/tickets`, {encryptedData});
//   } catch (error) {
//     console.log(error, "Error al crear los tickets");
//   }
// };

// export const transferTicket = async (
//   eventId,
//   ticketId,
//   userIdBuyer,
//   userIdReceiver
// ) => {
//   try {
//     await axios.put(
//       `${URL_API_VINCULANDO}/tickets/transfer/${eventId}/${ticketId}/${userIdBuyer}/${userIdReceiver}`
//     );
//   } catch (error) {
//     console.warn(error?.message)
//   }
// };

// export const uploadComprobante = (file, eid, uid) => {
//   const formdata = new FormData();
//   formdata.append("file", file);
//   axios
//     .put(
//       `${URL_API_VINCULANDO}/comprobantes/${eid}/uploadComprobante/${uid}`,
//       formdata
//     )
//     .then((res) => console.log(res, "COMPROBANTESUBIDO"))
//     .catch((err) => console.log(err.message));
// };

// export const deleteComprobante = (eid, uid, posicion) => {
//   try {
//     axios.delete(
//       `${URL_API_VINCULANDO}/comprobantes/${eid}/deleteComprobante/${uid}/${posicion}`
//     );
//   } catch (error) {
//     console.warn(error?.message)
//   }
// };
