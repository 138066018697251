import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { Login, Register, RegisterUserData, LandingPage } from '@/views'
import MyTabs from '../MyTabs/MyTabs'

const Stack = createNativeStackNavigator()

const AuthenticationStack = ({ origin }) => {
  return (
      <Stack.Navigator
        initialRouteName={
          origin === 'Register' ? 'RegisterForm' : 'Login'
        }
        screenOptions={{ headerShown: false }}
      >
        {/* <Stack.Screen name="LandingPage" component={LandingPage} /> */}
        <Stack.Screen name="Login" component={Login} />
        <Stack.Screen name="Register" component={Register} />
        <Stack.Screen name="RegisterForm" component={RegisterUserData} />
        <Stack.Screen name="MyTabs" component={MyTabs} />
      </Stack.Navigator>
  )
}

export default AuthenticationStack
