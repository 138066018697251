import React, { useEffect, useState } from 'react'
import { ScreenLayout } from '@/components'
import { useSelector } from 'react-redux'
import GuestsGrid from './components/GuestsGrid/GuestsGrid'
import PreVirtualRoom from './components/PreVirtualRoom/PreVirtualRoom'

const People = () => {
  const [virtualRoomStarted, setVirtualRoomStarted] = useState(false)
  const userEvents = useSelector((state) => state.event.userEvents)?.filter(
    (event) => event.eventStatus === 'en curso',
  )
  const selectedEventId =
    useSelector((state) => state.event.selectedEventId) || 2
  const selectedEvent =
    userEvents?.find((event) => event.eventId === selectedEventId) || {}
  const virtualRoomStartDate = selectedEvent?.preEventDate
  const virtualRoomStartTime = selectedEvent?.startPreEventTime

  const virtualRoomStartDateTime =
    virtualRoomStartDate && virtualRoomStartTime
      ? new Date(
          `${virtualRoomStartDate.split('T')[0]}T${virtualRoomStartTime}`,
        )
      : null

  useEffect(() => {
    const checkVirtualRoomStatus = () => {
      if (virtualRoomStartDateTime) {
        setVirtualRoomStarted(new Date() > virtualRoomStartDateTime)
      }
    }
    checkVirtualRoomStatus()
    const interval = setInterval(checkVirtualRoomStatus, 1000)
    return () => clearInterval(interval)
  }, [virtualRoomStartDateTime])

  return (
    <ScreenLayout headerTitle={'Vinculate'}>
      {virtualRoomStarted ? (
        <GuestsGrid selectedEvent={selectedEvent} />
      ) : (
        <PreVirtualRoom
          startDate={virtualRoomStartDateTime}
          userEvents={userEvents}
          selectedEvent={selectedEvent}
        />
      )}
    </ScreenLayout>
  )
}

export default People
