import { useEffect, useState } from 'react'
import { Keyboard, Alert } from 'react-native'
import { useDispatch } from 'react-redux'
import { useUserModel } from '../models/userModel'
import {
  handleUpload,
  deleteProfilePicture,
  editUser,
} from '@/redux/actions/userActions'
import * as ImagePicker from 'expo-image-picker'

export const useMyProfileController = () => {
  const dispatch = useDispatch()
  const { userId, userInfo, isLoading, fetchUserData, setIsLoading } =
    useUserModel()
  const [userData, setUserData] = useState({})
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [editInfo, setEditInfo] = useState(false)
  const [openSettingsModal, setOpenSettingsModal] = useState(false)
  const [picChanged, setPicChanged] = useState(false)
  const [inputChanged, setInputChanged] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isKeyboardVisible, setKeyboardVisible] = useState(false)

  useEffect(() => {
    fetchUserData()
  }, [])

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      () => {
        if (!isKeyboardVisible) setKeyboardVisible(true)
      },
    )
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        if (isKeyboardVisible) setKeyboardVisible(false)
      },
    )
    return () => {
      keyboardDidShowListener.remove()
      keyboardDidHideListener.remove()
    }
  }, [isKeyboardVisible])

  useEffect(() => {
    setIsLoading(true)
    if (userInfo) {
      setUserData({
        profilePictures: userInfo.profilePictures,
        name: userInfo.name,
        lastName: userInfo.lastName,
        email: userInfo.email,
        age: userInfo.age,
        phone: userInfo.phone,
        userName: userInfo.userName,
        dateOfBirth: userInfo.dateOfBirth,
        description: userInfo.description,
        genre: userInfo.genre,
        city: userInfo.city,
        sentimentalSituation: userInfo.sentimentalSituation,
      })
      setIsLoading(false)
      if (userInfo.profilePictures && emptyImage(userInfo.profilePictures[0])) {
        setTimeout(() => {
          setIsOpenModal(true)
        }, 1500)
      }
    }
  }, [userInfo, inputChanged])

  useEffect(() => {
    setIsLoading(true)
    if (picChanged) {
      // setTimeout(() => fetchUserData(), 2000)
      fetchUserData()
      setUserData({
        ...userData,
        profilePictures: userInfo.profilePictures,
      })
      setPicChanged(false)
      setEditInfo(false)
      setIsLoading(false)
      // setIsOpenModal(false)
      // fetchUserData()
    }
  }, [picChanged])

  const pickImage = async (origin = undefined) => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
    if (status !== 'granted') {
      alert(
        'Para subir una nueva foto de perfil necesitamos acceder a tu galería.',
      )
      return
    }

    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    })
    if (result) {
      setIsLoading(true)
      if (!result.canceled) {
        const response = await handleUpload(
          result.assets[0].uri,
          userId,
          currentImageIndex,
          dispatch,
        )
        if (response) {
          setPicChanged(true)
          setIsOpenModal(false)
          setIsLoading(false)
        }
      }
    }
  }

  const deleteImage = async () => {
    if (userId) {
      const response = await deleteProfilePicture(userId, currentImageIndex)
      if (response) {
        setPicChanged(true)
      }
    }
  }

  const saveModifiedInputs = async () => {
    setEditInfo(false)
    if (inputChanged && userId) {
      try {
        editUser(userId, userData, dispatch)
      } catch {
        Alert.alert('Ups algo salió mal, intentalo de nuevo!')
      }
      setInputChanged(false)
    }
  }

  const saveNewContactsMethods = (contactInfo) => {
    if (userInfo && Object.keys(contactInfo).length > 0) {
      editUser(userInfo.userId, { contactMethods: contactInfo }, dispatch)
      fetchUserData()
    }
  }

  const cancelModifiedInputs = () => {
    setEditInfo(false)
    fetchUserData()
  }

  const emptyImage = (url) => {
    return url.includes('/public/imagen/defaultPic.png')
  }

  return {
    userData,
    userInfo,
    setUserData,
    currentImageIndex,
    setCurrentImageIndex,
    editInfo,
    setEditInfo,
    openSettingsModal,
    setOpenSettingsModal,
    setInputChanged,
    isLoading,
    pickImage,
    deleteImage,
    isOpenModal,
    setIsOpenModal,
    saveModifiedInputs,
    cancelModifiedInputs,
    isKeyboardVisible,
    saveNewContactsMethods,
    emptyImage,
    fetchUserData,
  }
}
