import { useSelector, useDispatch } from 'react-redux'
import { getBondRequestsAction } from '@/redux/actions/bondActions'
import { useEffect } from 'react'
import { fetchStatus } from '@/models/fetch.model'
import { changeDefaultPic } from '@/adapters/guest.adapter'

export const useBondRequestsController = () => {
  const dispatch = useDispatch()
  const userId = useSelector((state) => state.auth.secureStore.userId)
  const eventId = useSelector((state) => state.event.selectedEventId)
  const bondRequests = useSelector((state) => state.bond.bondRequests)
  const bondRequestsStatus = useSelector(
    (state) => state.bond.bondRequestsStatus,
  )

  const formatBondRequest = bondRequests.map((bond) => changeDefaultPic(bond))
  const bondRequestsLoading =
    bondRequestsStatus === fetchStatus.LOADING ||
    bondRequestsStatus === fetchStatus.TO_FETCH

  const getBondRequests = () => {
    getBondRequestsAction(userId, eventId, dispatch)
  }

  useEffect(() => {
    getBondRequests()
  }, [])

  return {
    formatBondRequest,
    bondRequestsLoading,
    getBondRequests,
  }
}
