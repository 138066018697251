import React from 'react'
import { View, Image, Text } from 'react-native'
import styles from './InfoCardStyles'
import backgroundImage from '@/assets/Navbar/fondoNavbar.png'

const InfoCard = ({ userData }) => {
  return (
    <View style={styles.container}>
      <View style={styles.card}>
        <Image source={backgroundImage} style={styles.bckgImg} />
        <View style={styles.content}>
          {userData.name && (
            <Text style={styles.name}>
              {userData.name}, {userData.age}
            </Text>
          )}
          {/* {userData.sentimentalSituation &&
            userData.sentimentalSituation.value !== 'hide' && (
              <Text style={styles.state}>
                {userData.sentimentalSituation.label}
              </Text>
            )} */}
          <Text style={styles.location}>{userData.city}</Text>
          <Text style={styles.description}>{userData.description}</Text>
        </View>
      </View>
    </View>
  )
}

export default InfoCard
