import { fetchStatus } from '@/models/fetch.model'
import {
  GET_EVENT_GUESTS,
  GET_EVENT_GUESTS_LOADING,
  GET_EVENT_GUESTS_ERROR,
  GET_USER_EVENTS,
  SET_SELECTED_EVENT_ID
} from '@/redux/actions/index'

const initialState = {
  userEvents: [],
  userEventsStatus: fetchStatus.TO_FETCH,
  selectedEventId: undefined,
  eventsGuests: {
    //   [eventId]: {
    //     data: {
    //       guests: [],
    //       loadedGuestsPages: 0,
    //       numGuestsInDB: 0,
    //     },
    //     status: fetchStatus.TO_FETCH,
    //     error: null,
    //   }
  },
}

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_EVENTS:
      return {
        ...state,
        userEvents: action.payload.userEvents,
        selectedEventId: action.payload?.selectedEventId,
        userEventsStatus: fetchStatus.SUCCESS
      }
    case SET_SELECTED_EVENT_ID:
      return {
        ...state,
        selectedEventId: action.payload.selectedEventId
      }
    case GET_EVENT_GUESTS_LOADING:
      return {
        ...state,
        eventsGuests: {
          ...state.eventsGuests,
          [action.payload.eventId]: {
            data: {
              ...(state.eventsGuests[action.payload.eventId]?.data ?? {}),
              loadedGuestsPages: action.payload.currentPage
            },
            status: fetchStatus.TO_FETCH,
            error: null,
          }
        },
      }
    case GET_EVENT_GUESTS:
      return {
        ...state,
        eventsGuests: {
          ...state.eventsGuests,
          [action.payload.eventId]: {
            data: {
              guests: action.payload.currentPage === 1 ? action.payload.guests : [
                ...state.eventsGuests[action.payload.eventId].data.guests,
                ...action.payload.guests
              ],
              loadedGuestsPages: action.payload.currentPage,
              numGuestsInDB: action.payload.numGuestsInDB,
            },
            status: fetchStatus.SUCCESS,
            error: null,
          }
        },
      }
    case GET_EVENT_GUESTS_ERROR:
      return {
        ...state,
        eventsGuests: {
          ...state.eventsGuests,
          [action.payload.eventId]: {
            data: state.eventsGuests[action.payload.eventId]?.data ?? {},
            status: fetchStatus.ERROR,
            error: action.payload.error,
          }
        },
      }

    default:
      return state
  }
}

export default eventReducer
