import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  input: {
    backgroundColor: '#bfbfbf',
    borderRadius: 10,
    width: '100%',
    maxWidth: 343,
    height: 40,
    fontSize: 14,
    textAlign: 'center',
    marginTop: 30,
    fontWeight: '600',
  },
  passwordContainer: {
    width: '100%',
    maxWidth: 343,
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  showHideButton: {
    position: 'absolute',
    right: 15,
    height: 20,
    top: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    height: 20,
    width: 20,
  },
  iconErrors: {
    height: 20,
    width: 20,
    tintColor: 'red',
  },
})
