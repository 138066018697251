import React, { useEffect } from 'react'
import MyTabs from './MyTabs/MyTabs'
import AuthenticationStack from './Stacks/AuthenticationStack'
import { Loader } from '@/components'
import { useSelector, useDispatch } from 'react-redux'
import { useInitialLogic } from '@/config/initialLogic'
import { StyleSheet, View } from 'react-native'
// import { usePushNotifications } from '@/config/notifications/notifications.web.js'
import { SET_IS_USERINFO_EMPTY } from '@/redux/actions/index'

const Navigation = () => {
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => state.user.userInfo)
  const isUserInfoEmpty = useSelector((state) => state.auth.isUserInfoEmpty)
  const { isLoading, isLogued } = useInitialLogic()
  // usePushNotifications()

  useEffect(() => {
    if (userInfo && !userInfo.name && !userInfo.lastName) {
      dispatch({
        type: SET_IS_USERINFO_EMPTY,
        payload: true,
      })
    }
  }, [userInfo])



  return (
    <View style={styles.container}>
      {isLoading ? (
        <Loader />
      ) : isLogued ? (
        isUserInfoEmpty ? (
          <AuthenticationStack origin={'Register'} />
        ) : (
          <MyTabs />
        )
      ) : (
        <AuthenticationStack />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
  },
})

export default Navigation
