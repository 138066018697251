import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { MyProfile } from '@/views'
import ChangeInfoSettings from '@/views/MyProfile/components/ChangeInfoSettings/ChangeInfoSettings'
import ContactMethodScreen from '../../src/views/MyProfile/components/ContactMethodScreen/ContactMethodScreen'

const Stack = createNativeStackNavigator()

const SettingsStack = () => {
  return (
    <Stack.Navigator
      initialRouteName="MyProfile"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name="MyProfile" component={MyProfile} />
      <Stack.Screen name="ChangeInfoSettings" component={ChangeInfoSettings} />
      <Stack.Screen
        name="ContactMethodScreen"
        component={ContactMethodScreen}
      />
    </Stack.Navigator>
  )
}

export default SettingsStack
