import axios from 'axios'
import {
  GET_EVENT_GUESTS,
  GET_EVENT_GUESTS_LOADING,
  GET_EVENT_GUESTS_ERROR,
  GET_USER_EVENTS,
  SET_SELECTED_EVENT_ID,
} from './index'
import config from '../../../config'
import { guestsAdapter } from '@/adapters/guest.adapter'

const URL_API_VINCUFY = config.URL_API_VINCUFY

export const getUserEvents = async (userId, dispatch) => {
  try {
    const userEvents = await axios.get(
      `${URL_API_VINCUFY}/users/${userId}/events`,
    )

    const payload = {
      userEvents: userEvents.data,
      selectedEventId: 2,
      // selectedEventId: userEvents.data?.find(event => event.eventName === "Event Default 2")?.eventId
    }

    dispatch({
      type: GET_USER_EVENTS,
      payload,
    })
    return payload
  } catch (error) {
    console.warn(error?.message)
  }
}

export const setSelectedEventId = async (eventId, dispatch) => {
  try {
    dispatch({
      type: SET_SELECTED_EVENT_ID,
      payload: { selectedEventId: eventId },
    })
  } catch (error) {
    console.warn(error?.message)
  }
}

export const getEventGuestsAction = async (
  eventId,
  currentPage = 1,
  userId,
  bonds,
  dispatch,
) => {
  try {
    dispatch({
      type: GET_EVENT_GUESTS_LOADING,
      payload: { eventId, currentPage },
    })
    const guests = await axios.get(
      `${URL_API_VINCUFY}/events/${eventId}/guests?page=${currentPage}`,
    )
    dispatch({
      type: GET_EVENT_GUESTS,
      payload: {
        eventId,
        guests: guestsAdapter(guests.data.guests, userId, bonds),
        numGuestsInDB: guests.data.totalGuests,
        currentPage,
      },
    })
  } catch (error) {
    console.warn(error?.message)
    dispatch({
      type: GET_EVENT_GUESTS_ERROR,
      payload: {
        eventId,
        error: error?.message ?? '',
      },
    })
  }
}

// export const getEventInfo = async (id, dispatch) => {
//   try {
//     const user = await axios.get(`${URL_API_VINCULANDO}/events/${id}`)
//     dispatch({
//       type: GET_EVENT,
//       payload: user.data,
//     })
//   } catch (error) {
//     console.warn(error?.message)
//   }
// }

// export const uploadEventPicture = (file, idEvento, dispatch) => {
//   const formdata = new FormData()
//   formdata.append('file', file)
//   const img = axios
//     .put(`${URL_API_VINCULANDO}/events/${idEvento}/uploadImage`, formdata)
//     .then((res) => console.log(res))
//     .catch((err) => console.log(err.message))
//   // return{
//   dispatch({
//     type: GET_EVENT,
//     payload: img.data,
//   })
// }

// export const deleteEventPicture = (eid) => {
//   try {
//     axios.delete(`${URL_API_VINCULANDO}/events/${eid}/deleteEventPicture`)
//   } catch (error) {}
// }

// export const addAdmin = async (eventId, userId) => {
//     try {
//       await axios.put(
//         `${URL_API_VINCULANDO}/events/${eventId}/organizadores/${userId}`
//       );
//     } catch {}
//   };

//   export const deleteAdmin = async (eventId, userId) => {
//     try {
//       await axios.put(
//         `${URL_API_VINCULANDO}/events/${eventId}/delete-organizador/${userId}`
//       );
//     } catch {}
//   };

// export const getAdmin = async (eventId, dispatch) => {
//   try {
//     const admins = await axios.get(
//       `${URL_API_VINCULANDO}/events/${eventId}/organizers`,
//     )
//     dispatch({
//       type: GET_ADMINS,
//       payload: admins.data,
//     })
//   } catch (error) {
//     console.log(error)
//   }
// }

// export const getEventAdminInfo = async (eventId, dispatch) => {
//   try {
//     const eventAdminInfo = await axios.get(
//       `${URL_API_VINCULANDO}/events/admin/${eventId}`,
//     )
//     dispatch({
//       type: GET_EVENT_ADMIN_INFO,
//       payload: eventAdminInfo.data,
//     })
//   } catch (error) {
//     console.log(
//       error,
//       'Error al obtener información del evento para el administrador',
//     )
//   }
// }
