import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  datePickerContainer: {
    width: '100%',
    alignItems: 'center',
  },
  datePickerInput: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 40,
    width: 343,
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderRadius: 10,
    paddingHorizontal: 10,
    backgroundColor: '#bfbfbf',
    marginTop: 30,
  },
  datePickerLabel: {
    color: 'black',
    fontWeight: '600',
    fontSize: 14,
    paddingLeft: 8,
  },
  datePickerDivider: {
    height: '100%',
    width: 1.5,
    backgroundColor: 'grey',
    marginLeft: 40,
  },
  datePickerInputField: {
    width: 120, // Ajustamos para que encaje en la parte derecha
    height: '100%',
    border: 'none',
    backgroundColor: 'transparent',
    color: 'black',
    fontSize: 14,
    fontWeight: '600',
    textAlign: 'center',
    cursor: 'pointer', // Muestra el cursor de selección
  },
});
