import { Platform, StyleSheet } from 'react-native'

export default StyleSheet.create({
  screenLayout: {
    flex: 1,
    width: "100%",
    paddingBottom: Platform.OS === 'ios' ? 80 : 55,
    backgroundColor: 'black',
  },
})
