export const deviceNotificationsStatuses = {
    'BROWSER_NOT_SUPPORT': 'BROWSER_NOT_SUPPORT',
    'GRANTED': 'granted',
    'LOAD_TOKEN_FAILURE': 'LOAD_TOKEN_FAILURE',
    
    'DENIED': 'denied',
    'KNOWN': 'KNOWN',
}

export const shouldShowAllowNotificationsScreen = (status) => [
    deviceNotificationsStatuses.DENIED,
    deviceNotificationsStatuses.KNOWN,
].includes(status)