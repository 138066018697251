import React, { useState, useEffect } from 'react'
import { useFocusEffect } from '@react-navigation/native' // Asegúrate de importar esto
import styles from './GuestsGridStyles'
import ProfilePic from '@/views/People/components/ProfilePic/ProfilePic'
import backgroundImage from '@/assets/Navbar/fondoNavbar.png'
import info from '@/assets/People/info.png'
import { useGuestsController } from './controllers/useGuestsController'
import { useNavigation } from '@react-navigation/native'
import { CustomRefreshControl } from '@/components'
import { SetContactMethodModal } from '@/components'
import {
  View,
  FlatList,
  ActivityIndicator,
  Text,
  TouchableOpacity,
  Image,
} from 'react-native'

const GuestsGrid = ({ selectedEvent }) => {
  const navigation = useNavigation()
  const {
    guests,
    guestsError,
    userInfo,
    isLoading,
    isLoadingFetch,
    isLoadingMore,
    fetchMoreGuests,
    refreshGuests,
  } = useGuestsController()

  const [isAtBottom, setIsAtBottom] = useState(false)
  const [isOpenContactModal, setIsOpenContactModal] = useState(false)
  const eventName = selectedEvent?.eventName
  const truncatedEventName =
    eventName?.length > 19 ? `${eventName?.slice(0, 21)}...` : eventName

  useFocusEffect(
    React.useCallback(() => {
      if (
        userInfo?.contactMethods &&
        Object.keys(userInfo?.contactMethods)?.length === 0
      ) {
        setIsOpenContactModal(true)
      } else {
        setIsOpenContactModal(false)
      }
    }, [userInfo]),
  )

  const handleScroll = (event) => {
    const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent

    const isBottom =
      layoutMeasurement.height + contentOffset.y >= contentSize.height - 20
    if (isBottom && !isAtBottom) {
      setIsAtBottom(true)
      fetchMoreGuests()
    } else if (!isBottom) {
      setIsAtBottom(false)
    }
  }

  const goToDetail = (guest) => {
    navigation.navigate('ProfileDetail', { guest })
  }

  const getContent = () => {
    if (isLoading) return <ActivityIndicator size="large" color="#ffffff" />

    if (guestsError) return <Text style={styles.error}>{guestsError}</Text>

    if (!guests.length) return <Text>No hay invitados al evento aún.</Text>

    return (
      <>
        <View style={styles.buttonContainer}>
          <Text style={styles.eventName}>{truncatedEventName}</Text>
          <TouchableOpacity onPress={() => navigation.navigate('BondRequests')}>
            <View style={styles.button2}>
              <Text style={styles.buttonText}>Mis solicitudes</Text>
            </View>
          </TouchableOpacity>
        </View>
        <FlatList
          data={guests}
          renderItem={({ item }) => (
            <ProfilePic guest={item} goToDetail={goToDetail} />
          )}
          keyExtractor={(item) => item.userId.toString()}
          numColumns={3}
          refreshControl={
            <CustomRefreshControl
              onRefresh={refreshGuests}
              isLoadingFetch={isLoadingFetch}
            />
          }
          onScroll={handleScroll}
          scrollEventThrottle={16}
          showsVerticalScrollIndicator={false} // Ocultar el indicador de scroll
          ListFooterComponent={() =>
            !isLoadingMore ? (
              <View style={styles.footerList} />
            ) : (
              <ActivityIndicator
                style={styles.footerListLoading}
                size="large"
                color="#ffffff"
              />
            )
          }
          initialNumToRender={16}
        />
        <SetContactMethodModal
          isOpenContactModal={isOpenContactModal}
          setIsOpenContactModal={setIsOpenContactModal}
        />
      </>
    )
  }

  return <View style={styles.container}>{getContent()}</View>
}

export default GuestsGrid
