import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  error: {
    color: 'rgb(215, 64, 64)',
    marginTop: 8,
    marginBottom: -10,
  },
  footerList: {
    marginBottom: 70,
  },
  footerListLoading: {
    marginTop: 17,
    marginBottom: 17,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 30,
    gap: 10,
    width: '100%',
  },
  bckgImg: {
    width: 170,
    height: 33,
    resizeMode: 'cover',
    position: 'absolute',
    borderRadius: 7,
  },
  button2: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 7,
    padding: 5,
    width: 170,
    height: 33,
    borderRadius: 7,
    backgroundColor: '#9459CE',
    // paddingVertical: 10,
    // paddingHorizontal: 20,
  },
  buttonText: {
    color: 'white',
    fontSize: 13,
    marginBottom: 2,
  },
  info: {
    height: 14,
    width: 14,
    marginRight: 10,
  },
  eventName: {
    color: 'white',
    fontSize: 16.5,
    width: '100%',
    maxWidth: 175,
  },
})
