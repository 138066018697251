import { Platform } from 'react-native'
import * as SecureStore from 'expo-secure-store'

export const validateEmail = (email) => {
  if (email.length < 4) return 'Ingresá tu mail'

  const regexEmail = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/
  if (email.length < 4 || !regexEmail.test(email))
    return 'Este formato no es válido'
}

export const validatePassword = (password) => {
  if (password.length < 3) return 'Ingresá una contraseña'
}

export const validateConfirmPassword = (confirmPassword, password) => {
  if (confirmPassword.length < 2) return 'Confirma tu contraseña'
  if (confirmPassword !== password) return 'La contraseña es diferente'
}

export const saveSecureStoreData = async (token, userId, userEmail) => {
  try {
    localStorage.setItem('vincufyJwtToken', token)
    localStorage.setItem('userIdVincufy', userId.toString())
    localStorage.setItem('userEmailVincufy', userEmail)
  } catch (error) {
    console.warn(error)
    throw new Error('Error al guardar la información')
  }
}

export const getSecureStoreData = async () => {
  try {
    const token = localStorage.getItem('vincufyJwtToken')

    const userId = localStorage.getItem('userIdVincufy')

    const userEmail = localStorage.getItem('userEmailVincufy')

    return {
      token: token || null,
      userId: Number(userId) || null,
      userEmail: userEmail || null,
    }
  } catch (error) {
    console.error('Error al obtener la información del secure-store', error)
    return null
  }
}

export const deleteSecureStoreData = async () => {
  try {
    localStorage.removeItem('vincufyJwtToken')
    localStorage.removeItem('userIdVincufy')
    localStorage.removeItem('userEmailVincufy')
  } catch (error) {
    console.error('Error al eliminar la información del secure-store', error)
  }
}
