import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: 'black',
  },
  cardEventContainer: {
    flexDirection: 'column',
    paddingTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    width: '100%',
    height: '100%',
    padding: 10,
  }
})

export default styles
